@import "variables";
@import "oldbootstrap/mixins";
// Add main less styles here

/*
Site-Wide-Rules
*/
html, body {
  height: 100%;
}

.clearfix {
  clear: both;
}

#outdated-browser-bar {
  color: white;
  background-color: crimson;
  display: none;

  a {
    color: white;
    text-decoration: underline;
  }
}

.no-js, .no-fetch, .no-promises, .no-es6object {
  #outdated-browser-bar {
    display: block !important;
  }
}

.vertical-divider {
  width: 1px;
  background-color: $site-darkgrey;
  float: left;
  margin: 0px 10px;
}

h1, h3 {
  font-size: 20px;
  font-weight: lighter;
  clear: left;
  -webkit-font-smoothing: antialiased;
}

h3 {
  font-size: 16px;
  padding: 1rem 0 0 0;
}

.breadcrumb {
  background-color: inherit;
  padding-bottom: 0;
  margin-bottom: 10px;

  li {
    font-size: 12px !important;
  }
}

a {
  color: $site-blue;
}

.custom-select, .custom-check {
  width: 22px;
  height: 22px;
  float: left;
  clear: left;
  display: table;
  margin-right: 20px;
  border: solid 1px #B7B7B7;
  margin-bottom: 10px;
  color: $site-blue;
  cursor: pointer;

  i {
    width: 100%;
    display: block;
    text-align: center;
    height: 100%;
    vertical-align: middle;
    font-size: 16px;
    display: table-cell;
  }
}

.custom-field-label {
  float: left;
}

.content-header {
  margin-bottom: 0.75rem;

  .view-selector {
    font-size: 12px;

    color: $site-darkgrey;

    .view-container div {
      float: right;
      margin-left: 25px;
      margin-top: -10px;

      &:first-of-type {
        margin-top: -8px;
      }

      &:last-of-type {
        margin-left: 0px;
        margin-top: -8px;
      }
    }

    a.active {
      color: $site-darkgrey;
    }

    select {
      font-size: 11px;
      background: transparent;
      border: solid 1px white;
      height: 22px;
    }

  }
}

/*Brand Carousel Moz Fix*/
.brands-container .carousel-item {
  div {
    height: 300px;
    max-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  img {
    display: none;
  }

}

div.cost, div.go-price {
  float: left;
  margin-top: 18px;
  padding-bottom: 5px;

  span.rrp, .go_price_title {
    font-size: 8px;
    line-height: 1;
    float: left;
    margin-bottom: 3px;
  }

  span.cost, .go_price_cost {
    color: inherit;
    display: block;
    font-size: 26px;
    font-weight: $site-lightfont;
    float: left;
    clear: left;
    line-height: 1;

    a:hover {
      text-decoration: none;
    }
  }

  .go_price_cost {
    font-size: 22px;
    font-weight: bold !important;
  }

  span.no-cost {
    font-size: 12px !important;
    margin-top: 10px;
  }
}

div.go-price {
  .go_price_title {
    padding: 3px 5px;
    background: #3197EC;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    margin-bottom: 5px;
    display: block;
    font-size: 10px !important;
  }

  background: #ffffff;
  border: 2px solid #3197EC ;
  padding: 7px 8px;
  margin-top: 3px;
  border-radius: 5px;
  color: black !important;
  margin-left: 10px;
}

div.virtual-stock {
  float: left;
  width: initial !important;

  background: white;
  border-radius: 5px;

  span.est-stock-padding {
    margin-bottom: initial !important;
  }

  span.rrp {
    line-height: 1;
    float: left;
    margin-bottom: 3px;
  }

  .go_price_title {
    padding: 3px 5px;
    border-radius: 3px;
    margin-bottom: 5px;
    display: block;
  }

  span.cost, .go_price_cost {
    color: inherit;
    display: block;
    font-size: 26px;
    font-weight: $site-lightfont;
    float: left;
    clear: left;
    line-height: 1;

    a:hover {
      text-decoration: none;
    }
  }

  .go_price_cost {
    font-size: 19px;
    color: black !important;
  }

  span.no-cost {
    font-size: 12px !important;
    margin-top: 10px;
  }
}

.offer-qualify-true, .offer-qualify-false {
  font-size: 14px;
  background: rgba(0, 128, 0, 0.59);
  padding: 9px;
  display: inline-block;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  color: white;
}

.offer-qualify-false {
  background-color: rgba(255, 149, 6, 0.88);
}

.btn-secondary-outline {
  color: $site-darkgrey;
  border-color: $site-darkgrey;

  &:hover {
    color: $site-darkgrey;
    border-color: $site-darkgrey;
    background-color: $site-white;
  }
}

.btn-small {
  font-size: 10px;
  padding: .25rem .4rem;
}

.image-container {
  overflow: hidden;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.success-icon {
  background: url('../img/success-icon.png');
  background-size: 100px;
  width: 100px;
  height: 100px;
  margin: 30px auto 15px auto;
}

.markdown {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    font-weight: lighter;
  }

  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 15px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    font-size: 12px;
  }

  p, ul {
    font-size: 12px;
    width: auto;
    margin: 0px 0px 1rem 0px;
  }
}

.tab-pane {
  position: absolute;
}

.generic-page-container {
  background-color: white;
  padding: 0.9375rem;

  p, ul, li {
    font-size: 12px;
  }
}

.messages {
  margin-top: 0.5rem;
}

.carousel-control span.fa {
  top: 48%;
  position: absolute;
}

/*Buttons*/
.btn {
  border-radius: 0;
}

.btn-primary-outline {
  border-color: $site-blue;
  color: $site-blue;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited, .btn-primary-outline:hover, .btn-primary-outline:active, .btn-primary-outline:visited {
  background-color: $site-blue;
  border-color: $site-blue;
}

/*
BODY + CONTAINERS
*/

body {
  background-color: $site-background;
  font-family: $site-fontfamily;
}

#site-container {
  max-width: 91.875rem;
  height: auto;
  margin: 0 auto 3rem auto;
  padding: 0px 0.9375rem 200px 0.9375rem;
}

.nav-container {
  max-width: $site-standard-width;
  margin: 0 auto;
  display: block;
  height: 100%;
  position: relative;
}

/*
NAV
*/

.navbar {
  height: 55px;
  width: 100%;
  border-radius: 0;
  -webkit-box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, .75);
  -moz-box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, .75);
  box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, .75);

  .btn-secondary-outline {
    margin-top: 14px;
    font-size: 12px;
    padding: 2px 20px;
  }
}

#top-nav {
  background-color: $site-lightgrey;
  z-index: 10;
}

#bottom-nav {
  background-color: $site-white;
  padding-top: 0;
  z-index: 9;
}

.button-nav.col-md-4 {
  min-width: 360px;
}

.nav-specials {
  color: $site-blue !important;
}

.navbar-brand > img {
  max-width: 365px;
  max-height: 35px;
}

.nav-pills .nav-link {
  color: $site-darkgrey;
  font-weight: bold;
  border-radius: 0;
  cursor: pointer !important;
}

.navbar-form {
  padding-top: 14px;
  display: block;
  margin: 0 auto;
  width: 320px;

  .form-control {
    border-radius: 0;
    line-height: 1;
    padding: 6px 6px 4px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.35);
    font-size: 14px;
    width: 80%;
  }

  .btn {
    border-radius: 0;
    line-height: 1;
    padding: 7px 6px 7px 6px;
    box-shadow: 1px 1px 2px 0px rgba(0, 137, 251, 0.79);
    font-size: 12px;
    font-weight: bold;
    border-top-width: 2px;
  }
}

.sub-sub-nav .col {
  position: relative;
  min-height: 1px;
  padding-right: .9375rem;
  padding-left: .9375rem;
  float: left;
}

/* Profile */
#profile-nav {
  height: 100%;
  overflow: hidden;
  float: right;
  min-width: 190px;

  & > div {
    float: left;
  }
}

#profile-nav span {
  font-size: 12px;
  font-weight: $site-boldfont;
  float: left;
  margin-top: 6px;
  color: $site-darkgrey;

  &:nth-of-type(2) {
    margin-top: 0;
    font-size: 8px;
  }
}

.profile-avatar {
  width: 24px;
  height: 28px;
  background-image: url('../img/avatar.png');
  background-repeat: no-repeat;
  background-size: 24px;
  float: left;
  margin: 7px 5px 0px 0px;
}

#profile-nav > .vertical-divider {
  margin-top: 8px;
  height: 26px;
}

.nav-country {
  cursor: pointer;

  span {
    margin-top: 12px !important;
  }
}



.profile-flag {
  height: 14px;
  width: 22px;
  float: left;
  margin: 14px 5px 0px 0px;
}

#aus {
  background-image: url('../img/aus-flag.png');
  background-size: 22px 14px;
  background-repeat: no-repeat;
}

#nz {
  background-image: url('../img/nz-flag.png');
  background-size: 22px 14px;
  background-repeat: no-repeat;
}

.country-switcher {
  position: absolute;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  top: 47px;
  display: none;

  span {
    font-size: 12px;
  }

  & > span {
    padding-left: 1.5rem;
  }

  & > div {
    padding: 0.5rem 1.5rem 0.3rem;
    margin: 0.125rem 0px;

    &.active {
      background-color: rgba(49, 151, 236, 0.72);
    }

    span {
      vertical-align: top;
      line-height: 1.7;
    }
  }

  #nz, #aus {
    background-size: 30px 19px;
    height: 19px;
    width: 30px;
    background-size: 30px 19px;
    margin: 0px 0.5rem 0px 0px;
  }
}

/*Cart*/
.cart-button {
  margin-top: 14px;
  font-size: 12px;
  font-weight: 700;
  color: $site-black;
  line-height: 1.7;
  padding: .2rem .45rem;
  background-color: $site-lightgrey;
  height: 29px;
  /*
	span
	{
		width: 20px;
		height: 20px;
		background-image: url('../img/cart-black.png');
		display: block;
		background-size: 20px;
		float: left;
		margin-right: 8px;
	}*/

  &.empty {
    background-color: $site-verylightgrey;
    color: $site-darkgrey;
    border-color: $site-darkgrey;

    &:hover {
      color: $site-darkgrey;
      border-color: $site-darkgrey;
      background-color: $site-white;
    }
  }
}

/*SUBMENU*/
.sub-menu {
  position: absolute;
  top: -2px;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  z-index: 6;
  background-color: $site-blue;

  .dropdown-item {
    font-size: 12px;
  }

  .dd-title {
    color: $site-white;
  }

  .dd-title:hover {
    background-color: inherit !important;
  }

}

.ddm-shadow {
}

/*.dropdown-menu
{
	border-radius: 0;
	margin-top: 0px;
	font-size: 14px;
	background-color: $site-blue;
	border: none;
	z-index: 7;

	ul
	{
		list-style: none;
		margin: 0;
		padding: 0;
	}
}*/

.nav-link {
  padding: 16px !important;
  height: 55px;
  z-index: 8;
}

.text-right {
  text-align: right;
}

.open {
  .nav-link {
    background-color: $site-blue !important;
  }
}

.dropdown-item {
  color: $site-darkgrey;
  font-weight: bold;

  &.hover {
    background-color: #F5F5F5;
  }
}

/*

BRANDS

*/

.brands-container {
  .product-block {
    background-color: #01315a;
    height: 240px;
  }

  .btn-link:first-of-type {
    font-size: 16px;
  }
}

.block-container {
  padding: .75rem 1rem 1rem 1rem;
  clear: left;
  overflow: hidden;
}

.product-block {
  width: 230px;
  /*min-height: 240px;*/
  /*height: 240px;*/
  background-color: $site-lightgrey;
  float: left;
  margin: 0px 1rem 1rem 0px;
  -webkit-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.75);
  position: relative;
  padding: 10px;

}

.product-block.header {
  .image-container {
    height: 90%;
    width: 85%;
    margin: 0 auto;
  }

  h5 {
    font-size: 12px;
    color: white;
    font-weight: $site-lightfont;
    display: block;
    position: absolute;
    bottom: 0;
  }
}

.catalogue-brand-image {
  background-color: transparent !important;
  -webkit-filter: brightness(15%);
}

.product-block.list {
  .white-bg {
    position: absolute;
    background: white;
    width: 100%;
    height: 154px;
    left: 0;
    top: 0;
  }

  .image-container {
    height: 50%;
    width: 70%;
    margin: 0 auto;
    background-color: white;
  }

  h4 {
    color: $site-black;
    font-size: 14px;
    height: 42px;
    font-weight: $site-regularfont;
    line-height: 1.3;
  }

  h5 {
    color: $site-black;
    font-size: 12px;
    margin: 0px 0px 5px 0px;
    font-weight: $site-lightfont;
  }

  span.rrp {
    color: $site-blue !important;
  }

  div.cost {
    margin-top: 0;
  }

  span.cost {
    color: $site-blue;
    font-size: 16px;
  }

  .btn {
    float: right;
    margin: 7px 0 0 4px;

    &.cat-add-to-cart {
      border-color: #3197EC;
      height: 25px;
      padding: .1rem 0.25rem;

      i {
        font-size: 20px;
        color: #3197EC;
      }

    }
  }
}

/*Banners */

.right-banner, .left-banner {
  display: block;
  background-color: $site-white;
  color: $site-darkgrey;
  font-weight: $site-lightfont;
  border: solid 1px $site-darkgrey;
  text-align: center;
  transform: rotate(38deg);
  -ms-transform: rotate(38deg); /* IE 9 */
  -webkit-transform: rotate(38deg); /* Chrome, Safari, Opera */
  position: absolute;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .75);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .75);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .75);
  z-index: 3;
}

.left-banner {
  -webkit-transform: rotate(-38deg);
  transform: rotate(-38deg);
  -ms-transform: rotate(-38deg); /* IE 9 */
}

.product-block .right-banner, .product-block .left-banner {
  font-size: 10px;
  width: 180px;
  right: -36px;
  top: 36px;
}

.product-block .left-banner {
  left: -36px;
}

.product-list .right-banner, .product-list .left-banner {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
  -ms-transform: rotate(-40deg); /* IE 9 */
  font-size: 14px;
  height: 23px;
}

.product-list .left-banner {
  right: -38px;
  width: 260px;
  top: 56px;
}

.product-list .right-banner {
  top: 93px;
  width: 250px;
  right: -76px;
}

/*
LIST VIEW
*/

.product-list {
  display: table;
  margin-bottom: 1.5rem;
  position: relative;
  margin-left: 0;
  padding: 10px 5px;
  background-color: #f2faff;
  width: 100%;

  .bubble {
    display: table !important;
    color: $site-black;
    top: -18px;
    left: -18px;
  }

  & > div {
    float: none;
    display: table-cell;
    vertical-align: top;
  }

  .white-bg {
    background: white !important;
    height: 100% !important;
    width: 34% !important;
    right: 0 !important;
    top: 0 !important;
    position: absolute !important;
  }

  .product-info {
    a {
      color: inherit;
    }

    h4 {
      color: $site-black;
      font-size: 18px;
      font-weight: $site-regularfont;
    }

    h5 {
      color: $site-black;
      font-size: 16px;
      margin: 10px 0px 5px 0px;
      font-weight: $site-lightfont;
    }

    p {
      font-size: 12px;
      font-weight: $site-lightfont;
      color: $site-darkgrey;
      margin: 10px 0px 0px 0px;
      width: 90%;
    }

    span.cost, span.rrp {
      color: $site-blue;
    }

    span.cost sub {
      font-size: 10px;
      left: 2px;
      bottom: 0;
    }

    span {
      color: $site-black;
      display: block;
      font-size: 24px;
      font-weight: $site-lightfont;
      margin-bottom: 10px;
      line-height: 1;
    }

    .btn {
      font-size: 12px;
      float: right;
      margin-top: 15px;
      margin-right: 1rem;
    }
  }

  .product-image {
    div {
      width: 85%;
      height: 100%;
      position: absolute;
      top: 0%;
      left: 7.5%;
      bottom: 0;
      right: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

  }

  .product-buttons {
    vertical-align: bottom;

    button {
      float: right;
      font-size: 12px;
      margin-bottom: 10px;
    }

    button:first-of-type {
      margin-left: 5px;
    }

  }
}

/*
PRODUCT VIEW
*/

#logo {
  width: 90%;
  max-width: 150px;
}

.brand-logo {
  width: 70%;
  margin: 15px auto 25px auto;
  display: block;
}

.brand-description {
  font-size: 11px;
  text-align: justify;
}

.brand-container {
  padding: 0.9375rem;
}

#brand-detail-nav {
  min-width: 220px;

  .btn {
    white-space: normal;
  }
}

.product-container, .brand-container {
  background-color: white;
  /*padding: 0.9375rem;*/
  /*TEMP*/
  margin-bottom: 20px;

  h1 {
    color: $site-black;
    font-size: 18px;
    font-weight: $site-lightfont;
    padding: 0;
    margin: 0;
    line-height: normal;
  }

  p {
    font-size: 12px;
    font-weight: $site-lightfont;
    color: $site-darkgrey;
    margin: 15px 0px;
    width: 90%;
  }

  .info-box {
    background-color: #E5F3FD;
    padding: 10px;
    font-size: 12px;
    overflow: hidden;
    margin-top: 20px;

    .variable-selector {
      span {
        margin-bottom: 5px;
        display: block;
      }

      button {
        margin: 0px 5px 5px 0px;
        background-color: #fff;
        color: #3197EC;
        border: 1px solid #3197EC;
        min-width: 70px;
        word-wrap: break-word;
        max-width: 90px;
        line-height: 1.35;
        padding: 0rem 0.5rem;
        min-height: 34px;
        float: left;
      }

      .active {
        background-color: $site-blue;
        color: $site-white;
      }

      form {
        display: none;
      }
    }

    .availability {
      margin-top: 10px;
      clear: left;
      width: 100%;
      overflow: hidden;

      & > span {
        margin-right: 10px;
        margin-bottom: 5px;
        float: left;
        width: 100%;
      }

      .locations {
        float: left;
        margin-bottom: 10px;
      }

      .location {
        margin-right: 15px;
        float: left;

        span {
          float: left;
        }

        svg, .no-stock {
          clear: left;
          display: block;
          margin: 0 auto;
        }

        .no-stock {
          width: 100%;
          text-align: center;
          font-size: 9px;
          font-weight: bold;
          max-height: 12px;
        }
      }
    }

    .price-info {
      .btn {
        font-size: 9px;
        padding: .175rem 0.5rem;
        clear: left;
        float: left;
        margin-top: 15px;
        margin-bottom: 25px;
      }

      .dealer-price {
        display: none;
        clear: left;

        .new-price-flex-container {
          display: flex;
          justify-content: space-between;
        }

        div.cost {
          clear: none;
        }

        span.cost {
          font-size: 16px !important;
          color: inherit;
        }

      }

      span.gst {
        font-size: 8px;
        line-height: 1;
        float: left;
        margin: 14px 0px 0px 3px;
      }

      .no-auth, .not-available {
        font-size: 10px;
        float: left;
        margin-top: 11px;

        span {
          display: block;
        }
      }

      .not-available {
        display: none;
      }

    }

    & > .btn {
      clear: left;
      float: left;
      margin-top: 28px;
    }

  }

  .product-image {
    float: none;
    display: table-cell;
    vertical-align: top;
    overflow: hidden;
    height: 350px;

    div.image {
      width: 85%;
      height: 290px;
      position: absolute;
      top: 0;
      left: 7.5%;
      bottom: 0;
      right: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: none;
    }

    .thumbs-container {
      height: 40px;
      width: 85%;
      left: 7.5%;
      bottom: 0px;
      position: absolute;
      text-align: center;

      .thumbs {
        margin: 0 auto;
        display: inline-block;

        div {
          width: 40px;
          height: 25px;
          width: 60px;
          height: 40px;
          float: left;
          margin: 0px 5px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0.4;
          cursor: pointer;

          &.active {
            opacity: 1;
          }
        }
      }

    }

  }

  .product-detail {
    margin-top: 25px;

    .nav-tabs {
      margin: 0;
      padding: 0;
      border: 0;
    }

    .nav-tabs a {
      display: block;
      float: left;
      background-color: #7AC2FF;
      padding: .175rem .5rem;
      font-size: 12px;
      color: white;
      -webkit-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
      -moz-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
      box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .75);
      margin: 15px 15px 0px 0px;

      &:hover {
        text-decoration: none !important;
        color: #FFFFFF;
        background-color: #3CA6FF;
      }

      &.active {
        text-decoration: none !important;
        color: #FFFFFF;
        background-color: #3CA6FF;
      }

    }

    .tab-content {
      min-height: 400px;
    }

    #description {
      margin-top: 10px;

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1rem;
        font-weight: lighter;
      }

      h1 {
        font-size: 18px;
      }

      h2 {
        font-size: 16px;
      }

      h3 {
        font-size: 15px;
      }

      h4 {
        font-size: 14px;
      }

      h5 {
        font-size: 12px;
      }

      h6 {
        font-size: 12px;
      }

      p, ul {
        font-size: 12px;
        width: auto;
        margin: 0px 0px 1rem 0px;
      }

    }

    .tab-pane {
      border-radius: 0;
      background-color: inherit;
      position: static;

      .row {
        margin-top: 10px;
      }

      .spec {
        font-size: 12px;
        font-weight: lighter;
        color: $site-darkgrey;

        p {
          margin: 1px 0px 0px 0px;

          sup {
            top: -0.1rem;
          }
        }

        span {
          margin-top: 10px;
          display: block;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }

      .resource {
        width: 100%;
        margin: 10px 0px;
        font-size: 12px;
        font-weight: lighter;
        color: $site-darkgrey;
        float: left;
        clear: left;

        span {
          float: left;
          margin: 10px 0px 0px 10px;
          color: $site-darkgrey;
        }

        div {
          width: 25px;
          height: 35px;
          background-repeat: no-repeat;
          background-size: 25px;
          float: left
        }

        .pdf {
          background-image: url('../img/pdf.png');
        }

        .file {
          background-image: url('../img/file.png');
        }
      }

      .media {
        margin-top: 30px;
        color: $site-darkgrey;
        font-size: 12px;
        float: left;
        clear: left;
        width: 95%;

        span {
          float: right;
        }

        p, a {
          width: auto;
          float: left;
          clear: left;
          margin: 0;
        }

        .media-body a {
          color: $site-black;
          margin-top: 5px;
        }
      }

      .media-heading {
        font-size: 14px;
        display: block;
        float: left;
        font-weight: bold;
        color: $site-black;
      }

    }

  }

  .info-icons {
    margin-right: auto;
    background-color: #E5F3FD;
    border: solid 1px #C6E3F9;
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin: .9375rem;

    td {
      padding: 0.75rem 0.3rem 0;
    }

    .icon {
      width: 50px;
      height: 40px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    p {
      height: auto;
      font-size: 12px;
      margin: 0;
    }

  }

  .product-theatre {
    margin-top: 40px;

    img {
      max-width: 100%;
    }

    h6 {
      font-weight: $site-lightfont;
      font-size: 23px;
      color: $site-darkgrey;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.related-items, .related-items-v2 {
  /*VERISON TESTING*/
  display: none;

  & > div {
    background-color: white;
    border: $site-lightgrey solid 0.1px;
    overflow: hidden;
    padding: 10px 10px 0px 10px;
  }

  h6 {
    font-size: 16px;
    font-weight: $site-lightfont;
    margin: 0;
  }

  .row {
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;

    & > div {
      display: table-cell;
      vertical-align: top;
      float: none;
    }
  }

  span {
    float: left;
    clear: left;
    display: block;
  }

  .title {
    font-size: 14px;
  }

  .brand {
    font-size: 11px;
  }

  div.cost {
    margin-top: 7px;
    float: left;
    clear: left;
  }

  span.cost {
    font-size: 18px !important;
  }

  .btn {
    margin-top: 10px;
    float: left;
    clear: left;
  }

  .image-container {
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.related-items-v2 {
  /*VERISON TESTING*/
  display: block;

  .title {
    font-size: 12px;
    font-weight: lighter;
    color: $site-blue;

    span {
      float: none;
      display: inline;
    }
  }

  span.cost {
    font-size: 15px !important;
  }

  .btn {
    float: right;
    clear: none;
    margin-top: 8px;
  }

  .row {
    & > div {
      display: block;
    }
  }
}

.mini-cart {
  display: none;
  margin-bottom: 20px;

  .row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mini-cart-item {
    float: left;
    clear: left;
    line-height: 17px;

    .mini-qty {
      color: $site-black;
    }

    span {
      float: none;
      display: inline;
      font-size: 12px;
      font-weight: lighter;
      color: $site-blue;
      line-height: 1;
    }

  }

  button.btn {
    float: left;
    margin-top: 10px;
    margin-bottom: 1rem;
  }

  span {
    clear: none;
  }

  .mini-cart-loader {
    height: 80px;
    width: 80px;
    background: url('../img/mini-cart-loader.gif');
    margin: 20px auto;
  }
}

#bottom-nav > .nav-container > .row {
  .nav-link:hover {
    background-color: #3197EC;
    color: white;
  }

  .nav-link.active {
    background-color: #3197EC;
    color: white;
  }
}

.sub-nav {
  background-color: $site-blue;
  padding-top: 0;
  height: 45px;
  display: none;
  position: absolute;
  z-index: 5;

  .nav-item {
    a {
      color: $site-white;
      height: 45px;
      padding-top: 12px !important;
    }

    a.active, a:hover {
      background-color: $site-white !important;
      color: $site-blue !important;
    }
  }
}

.sub-sub-nav-container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 4;
  display: none;
}

.sub-sub-nav {
  background: $site-blue;
  width: auto;
  display: inline-block;
  padding-bottom: 20px;
  margin-top: 45px;

  .dd-title {
    color: $site-white !important;
    cursor: default;
  }

  .dd-title:hover {
    background-color: inherit;
    color: $site-white;
  }

  .row:first-of-type {
    margin-top: 15px;
  }

  .row {
    margin-top: 10px;
  }

  .dropdown-item {
    color: $site-darkgrey;
    font-weight: 700;
    font-size: 12px;
  }
}

.lightbox-cover {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  position: fixed;
  display: none;
}

.lightbox {
  position: fixed;
  z-index: 21;
  background-color: $site-background;
  padding: 1rem 20px;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, .75);
  display: none;
  width: 575px;

  h6 {
    font-size: 20px;
    font-weight: lighter;
    clear: left;
  }

  p {
    font-size: 12px;
    font-weight: $site-lightfont;
    margin: 0px 0px 20px 0px;
  }

  &.recommended {
    .product-block.list {
      width: 160px;
      height: 160px;
      margin: 0px 20px 20px 0px;

      span.cost {
        font-size: 14px;
      }

      .btn {
        font-size: 9px;
      }

      h4 {
        font-size: 11px;
        margin-bottom: 4px;
        line-height: 1.4;
        margin-top: -7px;
        white-space: normal;
        position: static;
      }

      h5 {
        font-size: 10px;
        margin-bottom: 7px;
        color: $site-blue;

      }

      .image-container {
        height: 45%;
      }

      &:nth-child(3n+3) {
        margin-right: 0;
      }

    }
  }

  &.qty {
    h6 {
      margin-bottom: 20px;
    }

    p {
      margin: 5px 10px 15px 0px;
      float: left;
      clear: left;
      font-size: 14px;
    }

    input {
      width: 100px;
      margin-bottom: 1rem;
    }
  }

  .close-container {
    background: $site-lightgrey;
    float: left;
    width: 27px;
    height: 27px;
    position: absolute;
    top: -7px;
    right: -7px;
  }

  svg {
    display: block;
    cursor: pointer;
  }

  .close-x {
    stroke: $site-darkgrey;
    fill: transparent;
    stroke-linecap: round;
    stroke-width: 5;
  }

  .add-loading-icon, .add-error-icon {
    height: 80px;
    width: 80px;
    background: url('../img/mini-cart-loader.gif');
    margin: 20px auto;
  }

  .add-error-icon {
    display: none;
    background-image: url('../img/error.png');
    background-size: 80px;
  }

  .btn {
    clear: left;
    float: left;
    margin-top: 10px;
  }

  .qty-selector {
    width: 90px;
    overflow: hidden;
    height: 35px;
    border-radius: 4px;
    margin-bottom: 1rem;
    float: left;

    input {
      width: 60px;
      background: $site-white;
      border: 0;
      margin: 0;
      height: 100%;
      padding-left: 10px;
      float: left;
    }

    span {
      height: 17px;
      background: #E5F3FD;
      float: left;
      width: 30px;
      text-align: center;
      box-sizing: content-box;
      line-height: 1;
      color: $site-blue;
      cursor: pointer;
      font-size: 10px;
      display: table;

      &:first-of-type {
        border-bottom: solid 1px #D2DFE8;
      }

      &:active {
        background: #FFFFFF;
      }

      i {
        display: table-cell;
        vertical-align: middle;

        &:last-of-type {
          padding-top: 2px;
        }
      }

    }
  }

  .manual-add {
    display: none;
  }

  p.manual-add {
    margin-top: 100px !important;
    font-weight: bold;
    margin-bottom: 0;
  }

}

.cart {
  background-color: $site-lightgrey;
  padding: 1rem;
  overflow: hidden;
  margin-bottom: 1rem;

  h1 {
    padding: 0;
  }

  .brand {
    background-color: $site-white;
    border: solid $site-middlegrey 1px;
    padding: 1rem;
    margin-bottom: 1.5rem;

    h4 {
      font-size: 18px;
      color: $site-black;
      font-weight: $site-lightfont;

    }
  }

  .discounts {
    .brand-item {
      border: rgba(155, 206, 160, 0.65) solid 1px;
      background-color: rgba(155, 206, 160, 0.44);

      .item-info {
        input {
          margin: 1px 0.35rem 0px 0.5rem;
          width: auto;
          float: left;
        }

        label {
          float: left;

          &.active {
            font-weight: bold;
          }
        }

        span {
          margin: 0;
          margin-right: 0.25rem;
          float: left;
          min-width: 0 !important;

          &:nth-of-type(2n) {
            margin: 0px 5px 0px 10px;
          }
        }
      }
    }

    .total {
      font-size: 17px;
      margin: 0.25rem 0px 0.15rem 0px;
    }

    .discount-loader {
      display: none;
    }

    p.discount-loader {
      color: #7BB37A;
    }

    div.discount-loader {
      background: url('../img/discount_loading.gif');
      width: 300px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: 40%;
      background-position: 0px -40px;
    }
  }

  .brand-item {
    border: #DCEBF7 solid 1px;
    background-color: #FFFFFF;
    overflow: hidden;
    position: relative;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.5rem;

    & > div {
      float: left;
    }

    .image-container {
      width: 74px;
      height: 42px;
      margin-right: 10px;
    }

    .item-info {
      font-size: 11px;

      p {
        margin: 0;
        font-weight: bold;
        font-size: 14px;
        color: $site-black;
      }

      span {
        &:first-of-type {
          min-width: 105px;
          display: inline-block;
        }

        margin-right: 0.75rem;
      }

      strong {
        color: $site-black;
      }

      .qty {
        margin-right: 0;
      }

      input {
        width: 35px;
        margin-right: 0.75rem;
      }

      & > div {
        background: red;
        overflow: hidden;
        width: 36px;
        display: inline-block;
        margin-bottom: 2px;
        vertical-align: middle;
        margin-right: 0.75rem;

        input {
          width: 20px;
          height: 21px;
          float: left;
          line-height: 1;
          background: $site-white;
          border: 1px solid $site-middlegrey;
          border-right: none;
        }

        button {
          float: left;
          height: 10.5px;
          background-color: $site-white;
          width: 16px;
          border: 1px solid $site-middlegrey;
          outline: none;
          background-image: url('../img/arrow_up.png');
          background-repeat: no-repeat;
          background-position: center;

          &:last-of-type {
            border-top: none;
            background-image: url('../img/arrow_down.png');
          }

          &:active {
            background-color: $site-blue;
          }

        }
      }

      & > form {
        display: inline;

        & > button {
          border: 0;
          background: transparent;
          padding: 0;
          line-height: 1;
        }

        span:first-of-type {
          display: none;
        }
      }
    }

    .item-total {
      float: right;
    }

    .item-total {
      span {
        clear: both;
        float: right;

        &:first-of-type {
          font-size: 12px;
          font-weight: bold;
        }

        &:last-of-type {
          font-weight: $site-lightfont;
          font-size: 16px;
        }
      }

    }
  }

  .partial-discounts {
    display: none;

    .btn {
      font-size: 11px;
      padding: 5px 10px;
      float: right;
      margin-top: 5px;
    }

    .brand-item {
      border: rgba(199, 187, 36, 0.65) solid 1px;
      background-color: rgba(245, 184, 4, 0.44);
    }
  }

  .brand-totals {
    overflow: hidden;

    span {
      float: right;
      clear: both;
      font-size: 14px;
      margin-right: 2px;

      strong {
        margin-left: 10px;
        min-width: 95px;
        display: inline-block;
        text-align: right;
      }

      input {
        margin-right: 0.25rem;
      }
    }

    .discount, .discount-loading {
      color: $site-green;
    }

    .discount {
      .active {
        color: $site-blue;
      }

      label {
        margin-bottom: 0;
      }

    }

    .discount-loader {
      height: 80px;
      width: 80px;
      background: url('../img/discount-loader.gif');
      float: right;
      clear: both;
      margin-right: 53px;
    }

    .subtotal {
      font-size: 17px;
      margin: 0.25rem 0px 0.15rem 0px;
    }

  }

  .brand-discount {
    background-color: $site-green;
    background-color: #73b372;
    padding: 0.3rem 0.5rem;
    margin-top: 1rem;
    display: table;
    width: 100%;

    span {
      font-size: 12px;
      color: $site-black;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      display: table-cell;
      vertical-align: middle;
    }

    div {
      display: table-cell;
      vertical-align: middle;
    }

    button {
      font-size: 12px;
      border-color: #000;
      background: 0 0;
      color: #000;
      padding: 0.1rem 0.5rem;
      float: right;

      &:hover {
        color: $site-white;
        border-color: $site-white;
      }
    }
  }

  .cart-totals {
    float: right;
    font-size: 15px;
    color: $site-black;

    span {
      display: inline-block;
      float: right;
      clear: both;

      strong {
        width: 110px;
        margin-left: 20px;
        display: inline-block;
        text-align: right;
      }
    }

    .total {
      font-size: 20px;
      margin-top: 10px;
    }
  }

  .btn.btn-primary {
    float: right;
    clear: right;
    margin-top: 1rem;
    width: 200px;
    -webkit-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
    -moz-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
    box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, .75);
  }

  .empty {
    h4 {
      margin-top: 1rem;
      font-size: 16px;
    }

    p {
      font-size: 12px;
      color: $site-darkgrey;
    }
  }
}

.banner-wrapper {
  position: absolute;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 85%;
  top: 0;
  left: 0;
}

.product-list .banner-wrapper {
  height: 100%;
}

.bubble {
  font-size: 11px;
  word-wrap: break-word;
  background-color: orange;
  display: table;
  width: 45px;
  text-align: center;
  height: 45px;
  position: absolute;
  z-index: 4;
  border-radius: 23px;
  top: -9px;
  left: -9px;

  span {
    word-wrap: break-word;
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
  }
}

.extended-terms {
  margin: -2px 0px 0px 7px;
  display: block;
  float: left;
  height: 20px;
  overflow: hidden;
  background-image: url('../img/terms.png');
  text-align: right;
  width: 65px;
  background-repeat: no-repeat;
  background-size: 16px;
  line-height: 22px;
  color: #58A34B;
}

.product-order-title {
  float: left;
  display: block;
}

.display-offer {
  background: orange;
  height: 25px;
  display: table;
  overflow: hidden;
  padding: 10px;
  border-radius: 3px;
  margin-top: 1rem;

  span {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    display: block;
    color: black;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.address-selection {
  background-color: white;
  padding: 1rem;

  select.select-address {
    display: none;
  }

  h1 {
    padding: 0;
  }

  .dropship {
    display: none;
  }

  .freight-charge {
        font-size: 24pt;
    }

  .freight, .dropship {
    padding: 0.25rem 0 0 0;
    float: right;

    span, strong {
      display: block;
    }



    span.discount-notification, span.dropship-notification {
      margin-top: 5px;
      font-size: 10px;
      color: #73B372;
      font-weight: bold;
    }
  }

  .address {
    display: table;
    margin-bottom: 1rem;
    padding: 0.25rem 0.5rem 1.25rem 0px;
    border-top: solid 1.5px transparent;
    border-bottom: solid 1px #D0D0D0;

    &.selected {
      background-color: rgba(49, 151, 236, 0.7);
      border-top: solid 1.5px darkgrey;

      button {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }

    div {
      display: table-cell;
      vertical-align: middle;

      &:first-of-type {
        width: 200px;
      }
    }

    p {
      margin: 0px 20px 0px 0px;
      font-size: 14px;
      font-weight: $site-lightfont;
    }

    button {
      -webkit-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
      -moz-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
      box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, .75);
    }
  }

  .customer-address-container {
    padding: .5rem .5rem .0rem .5rem;
    display: inline-block;
    border-top: solid 1.5px transparent;

    & > button {
      -webkit-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
      -moz-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
      box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, .75);
    }

    input, select {
      line-height: 1;
      font-size: 12px;
    }

    &.selected {
      background-color: rgba(49, 151, 236, 0.7);
      border-top: solid 1.5px darkgrey;

      & > button {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  .customer-address {

    font-size: 12px;
    overflow: hidden;
    color: $site-darkgrey;
    display: none;

    span {
      margin: 10px 0px;
      display: block;
    }

    input, select {
      margin: 0px 0px 5px 0px;
      height: 38px;
      width: 85%;
      clear: left;
      float: left;
    }

    button {
      margin: 0.5rem 0px;
    }

    .long {
      width: 95%;
    }

    #id_address_region {
      width: 50%;
      margin-right: 1%;
    }

    #id_address_postal_code {
      width: 34%;
      clear: none;
    }
  }

}

.payment-link-gateway-selection {
  .gateway-image {
    max-width: 70px !important;
  }
}

.gateway-selection {
  background-color: white;
  padding: 1rem;
  min-height: 250px;
  overflow: hidden;

  h1 {
    margin-bottom: 30px;
  }

  .gateway {
    float: left;
    clear: left;
    min-width: 196px;
    background: transparent;
    color: #292929;
    width: auto;
    text-align: left;
    border: solid 1px #dcdcdc;
    padding: 10px 18px;
    font-size: 14px;

    .gateway-image {
      margin-right: 10px;
      max-width: 30px;
    }

    &:hover {
      color: white;
      background: #5491d6;
      border: solid 1px #3677c1;
    }
  }

  .gateway-surcharge, .gateway-surcharge-spacer {
    float: left;
    clear: left;
    margin-bottom: 20px;
    font-size: 10.5px;
    margin-top: 4px;
  }

  .hidden-gateways {
    overflow: hidden;
    display: none;
    float: left;
    clear: left;
  }

  .toggle-hidden-gateways {
    float: left;
    clear: left;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 15px;

    i {
      transition: all 0.4s ease;

      &.chevron-flip {
        transform: rotate(180deg);
      }
    }

  }

}

#payment-lightbox {
  position: fixed;
  z-index: 21;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  width: 575px;
}

.payment-container, .gateway-response {
  background-color: #287eb3;
  margin: 0 auto;
  width: 260px;
  box-sizing: content-box;
  padding: 35px 50px;
  border-radius: 4px;
  box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, .75);
  overflow: hidden;

  .payment-header {
    color: white;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    i {
      margin-right: 10px;
    }
  }

  .payment-change-method {
    color: white;
    float: left;
    font-size: 11px;
    margin-top: 15px;
    margin-bottom: 0px;

    a {
      color: inherit;
    }
  }
}

.gateway-response {

  width: 470px;

  &.error {

  }

  .payment-error-header, .payment-error {
    color: #ff9d00;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: uppercase;

    i {
      margin-right: 10px;
    }
  }

  .payment-error {
    color: white;
    text-transform: none;
    font-size: 15px;
  }

  button {
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: auto;
    height: 37px;
    border: 0;
    text-decoration: none;
    background: #45b1e8;
    background-image: -webkit-linear-gradient(#45b1e8, #3097de);
    background-image: -moz-linear-gradient(#45b1e8, #3097de);
    background-image: -ms-linear-gradient(#45b1e8, #3097de);
    background-image: -o-linear-gradient(#45b1e8, #3097de);
    background-image: -webkit-linear-gradient(#45b1e8, #3097de);
    background-image: -moz-linear-gradient(#45b1e8, #3097de);
    background-image: -ms-linear-gradient(#45b1e8, #3097de);
    background-image: -o-linear-gradient(#45b1e8, #3097de);
    background-image: linear-gradient(#45b1e8, #3097de);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(46, 86, 153, 0.3);
    -webkit-box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    -moz-box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    -ms-box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    -o-box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    -webkit-transition: box-shadow .15s linear;
    -moz-transition: box-shadow .15s linear;
    -ms-transition: box-shadow .15s linear;
    -o-transition: box-shadow .15s linear;
    transition: box-shadow .15s linear;

    margin-top: 10px;
    float: left;
    padding: 0px 10px;

  }

  a:last-of-type {
    button {
      margin-left: 15px;
    }
  }

}

#payment-lightbox-cover {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  position: fixed;
}

#payment-lightbox {
  position: fixed;
  z-index: 21;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  width: 575px;
}

#payment-form {

  .form-row {
    float: left;
    clear: left;
  }

  .payment-input {

    border-radius: 4px;
    overflow: hidden;
    display: block;
    float: left;
    height: 37px;
    position: relative;
    width: 250px;
    margin-bottom: 10px;
    border: solid 1px transparent;

    &.focus {
      box-shadow: 0px 0px 6px #007eff;
    }

    &.error {
      border: solid 1px red;

      &.focus {
        box-shadow: 0px 0px 4px #f00;
      }
    }

    &.valid {
      border: solid 1px #28a900;

      &.focus {
        box-shadow: 0px 0px 4px #28a900;
      }
    }

    i {
      position: absolute;
      left: 1px;
      top: 3px;
      pointer-events: none;
      z-index: 10;
      font-size: 18px;
      top: 0;
      left: 6px;
      height: 37px;
      line-height: 37px;
      color: #4e4e4e;
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 37px;
      outline: none;
      border: none;
      background: #fff;
      padding: 0 6px 2px 32px;
    }

  }

  #exp-input, #cvc-input {
    width: 122.5px;
    margin-right: 5px;
  }

  select {
    display: none;
  }

  button, .attempting-payment {
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 250px;
    height: 37px;
    border: 0;
    text-decoration: none;
    background: #45b1e8;
    background-image: -webkit-linear-gradient(#45b1e8, #3097de);
    background-image: -moz-linear-gradient(#45b1e8, #3097de);
    background-image: -ms-linear-gradient(#45b1e8, #3097de);
    background-image: -o-linear-gradient(#45b1e8, #3097de);
    background-image: -webkit-linear-gradient(#45b1e8, #3097de);
    background-image: -moz-linear-gradient(#45b1e8, #3097de);
    background-image: -ms-linear-gradient(#45b1e8, #3097de);
    background-image: -o-linear-gradient(#45b1e8, #3097de);
    background-image: linear-gradient(#45b1e8, #3097de);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(46, 86, 153, 0.3);
    -webkit-box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    -moz-box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    -ms-box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    -o-box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    box-shadow: 0 1px 0 rgba(46, 86, 153, 0.15), 0 0 4px rgba(86, 149, 219, 0), inset 0 2px 0 rgba(41, 102, 20, 0);
    -webkit-transition: box-shadow .15s linear;
    -moz-transition: box-shadow .15s linear;
    -ms-transition: box-shadow .15s linear;
    -o-transition: box-shadow .15s linear;
    transition: box-shadow .15s linear;

    margin-top: 10px;
    float: left;

    &:disabled {
      opacity: 0.4;
    }
  }

  .attempting-payment {
    display: none;
    line-height: 34px;
    text-align: center;
    background: #2e8bc5;
    color: rgba(255, 255, 255, 0.83);
    cursor: default;

    i {
      margin-right: 10px;
    }
  }

  .payment-input-success {
    float: left;
    margin-left: 5px;
    color: #28a900;
    display: none;

    i {
      font-size: 25px;
      height: 37px;
      line-height: 36px;
      position: absolute;
    }
  }

}

.order-summary {
  background-color: white;
  font-size: 14px;
  overflow: hidden;
  padding: 0.5rem 0.75rem;

  h5 {
    font-size: 20px;
    font-weight: $site-lightfont;
    color: $site-blue;
  }

  td, th {
    padding-left: 0;
  }

  .table {
    margin-bottom: 0.5rem;
  }

  .return-to-cart {
    float: right;
    font-size: 14px;
    padding-right: 0.5rem;
  }

}

/*Index Styles*/

/* New Banners */
.banner-2022
{
  max-width: 135vh;
  margin: 0 auto;
}

.banner-outer {
  width: 100%;
  padding-top: 50%;
  position: relative;
}

.banner-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.banner-inner a {
    display: block;
    height: 100%;
    width: 100%;
}

.banner-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-inner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}


.banner-text-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10%;
  box-sizing: border-box;
  color: white;
}

.banner-text-container.right
{
  justify-content: flex-end;
}

.banner-text-container.right .banner-text {
  text-align: right;
}

.banner-text h4
{
  font-weight: bold;
}

.banner-text span
{
  padding: 10px 15px;
  background: #3097ec;
  margin-top: 0;
  display: inline-block;
  margin-top: 60px;
}

.banner-mobile-poster
{
  display: none;
}


@media only screen and (max-width: 550px) {
  .banner-inner video
  {
    display: none;
  }

  .banner-mobile-poster
  {
    display: block;
  }
}





.home {
  /*max-width: 970px;*/
  margin: 0 auto;

  .banner {
    width: 100%;
    max-width: 970px;
    margin: 0 auto;
    display: block;
    margin-top: 1rem;

    .carousel-indicators {
      bottom: -5px !important;
    }
  }

  

  .pointer {
    width: 100% !important;
    background-color: $site-white;
    font-size: 11px;
    padding: 0.25rem 1rem;
    white-space: nowrap;
    overflow: hidden;
    font-weight: lighter;
    max-width: 1000px;
    margin: 1rem auto;
    border: solid 1px #D3E8FB;

    span:last-of-type:after {
      content: "";
    }

    span:after {
      padding-right: .5rem;
      padding-left: .5rem;
      color: #818a91;
      content: "| ";
    }
  }

  .relationship-manager-block {
    background: lightblue;
    padding: 12px;

    p {
      margin: 0px;
    }
    a {
      color: black;
      text-decoration: none;
      font-weight: bold;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .custom-home-text-block {
    background: white;
    padding: 12px;

    p {
      margin: 0px;
    }
  }

  .product-loading-icon {
    height: 80px;
    width: 80px;
    background: url('../img/mini-cart-loader.gif');
    margin: 20px auto;
  }

  .best-sellers {
    background: #E7F4FD;

    & > h4 {
      background-color: #D3E8FB;
      display: block;
      font-weight: bold !important;
      color: black !important;
      font-size: 14px !important;
      padding: 0.5rem 0.75rem;
    }

    .product-block {
      width: 100%;
      margin: 0px 2% 1rem 0px;

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }

    .info {
      h4 {
        height: 42px;
        line-height: 14px;
      }

      a {
        font-size: 12px;
      }
    }

    .image-container {
      height: 118px !important;
    }

    .offer-bottom-container {
      margin-top: 240px;
    }

    .offer {
      height: 218px;
      position: absolute;
    }

    .offer .image-container {
      height: 95px !important;
    }

    div.cost {
      margin-top: 10px;
    }

    span.rrp {
      font-size: 6.5px;
    }

    span.cost {
      font-size: 13px;
    }

    .white-bg {
      height: 131px;
    }

  }

  .specials {
    position: relative;
    height: 490px;
    background: transparent;

    & > h4 {
      background-color: #D3E8FB;
      display: block;
      font-weight: bold !important;
      color: black !important;
      font-size: 14px !important;
      padding: 0.5rem 0.75rem;
    }

    .offer-container {
      position: absolute;
      top: 39px;
      width: 100%;

      &:nth-of-type(even) {
        top: 273px;
      }
    }

    .offer {
      width: 100%;
    }
  }

  .best-sellers .product-block.list {
    height: auto !important;
  }
}

/*Mobile Adustments*/
@media (max-width: 1024px) {
  /*Products*/
  .related-items-v2 .btn {
    float: left;
  }

  .related-items-v2 .row {
    margin-bottom: 30px;
  }

  .product-container .info-icons p {
    font-size: 11px;
  }
}

/* Portrait tablets and small desktops */
/*@media (min-width: 768px) and (max-width: 991px) {*/

/*@media (max-width: 991px) {
	.navbar-form .form-control
	{
		width: 60%;
	}

	.cart-button span
	{
		display: none;
	}

}*/

/*@media (max-width: 810px) {
	.navbar-form .form-control
	{
		width: 50%;
	}
}

@media (max-width: 769px) {

	.nav-link
	{
		font-size: 13px;
		padding-top: 19px;
	}

}
*/

/*Scheme 2*/
#top-nav, body {
  background-color: rgba(255, 255, 255, 0.9);
}

body {
  font-family: "Familiar Pro", "Times New Roman", sans-serif;
  //background-image: url('../img/bg_2.png');
  background-repeat: repeat-x;
  background-color: $site-verylightgrey;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d6e7fe+0,fefefe+100 */
  /*background: rgb(214,231,254); *//* Old browsers */
  /*background: -moz-linear-gradient(top, rgba(214,231,254,1) 0%, rgba(254,254,254,1) 100%);*/
  /* FF3.6-15 */
  /*background: -webkit-linear-gradient(top, rgba(214,231,254,1) 0%,rgba(254,254,254,1) 100%);*/
  /* Chrome10-25,Safari5.1-6 */
  /*background: linear-gradient(to bottom, rgba(214,231,254,1) 0%,rgba(254,254,254,1) 100%); *//* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d6e7fe', endColorstr='#fefefe',GradientType=0 );*/
  /* IE6-9 */
}

#bottom-nav {
  background-color: rgb(60, 166, 255);
  box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.75);
}

.navbar {
  box-shadow: none;
}

.nav-pills .nav-link {
  color: white;
}

.nav-specials {
  color: #FFD632 !important;
}

.cart-button {
  box-shadow: none;
  background-color: white;
}

#bottom-nav > .nav-container > .row .nav-link.active {
  background-color: #FFFFFF;
  color: #3CA6FF;
}

.navbar-form .form-control {
  border: 1px solid white;
  box-shadow: none;
}

.navbar-form .btn {
  background: white;
  border: 1px solid white;
  color: black;
  box-shadow: none;
  border-top-width: 2px;
}

.product-block {
  background-color: rgb(242, 250, 255);
  box-shadow: none;
}

.right-banner, .left-banner {
  box-shadow: none;
  color: white;
  background-color: #FB0000;
  border: none;
  height: 16px;
}

.product-block.list {

  .image-container {
    /*height: 65%;*/
    height: 145px;
    width: 90%;
  }

  div.info {
    position: relative;
    height: 30px;
    margin-top: 5px;
    display: table;
  }

  h4 {
    margin: 0px;
    bottom: 0;
    width: 100%;
    vertical-align: middle;
    display: table-cell;

    a:hover {
      text-decoration: none;
    }
  }

  h5 {
    color: #000;
    font-size: 12px;
    margin: 0px;
    font-weight: lighter;
  }

  div.cost {
    margin-top: 5px;
  }

  span.cost sub {
    font-size: 10px;
    left: 2px;
    bottom: 0;
  }
}

.cart {
  background-color: #f2faff;

  .freight {
    background-color: #E9F6FF;
  }
}

/*Account*/
.account {

  .nav-tabs {
    border: none;
    margin-left: 0.5rem;
    padding-bottom: 0.25rem;
    display: inline-block;

    li {
      display: inline;
      margin-right: 1rem;
      padding: 0.5rem 0.5rem 0.4rem 0.5rem;
      text-align: center;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        text-decoration: none;
      }
    }

    li.active {
      color: white !important;

      a {
        color: rgba(122, 194, 255, 0.9);
      }

    }

  }

  div.account-item {
    margin-left: 1rem;

  }

  h3, h6 {
    font-weight: normal;
  }

  h6 {
    margin-top: 1rem;
  }

  p {
    font-weight: lighter;
    margin-top: 0.5rem;
  }

  h6, p {
    font-size: 14px;
    margin-bottom: 0;

  }

  .grey-first tr td:nth-of-type(2n+1) {
    color: grey;
  }

}

footer {
  background-color: black;
  height: 25px;
  position: fixed;
  bottom: 0;
  min-width: 100%;
  z-index: 50;

  div {
    margin: 0 auto;
    padding: 1rem;
    max-width: 91.875rem;
  }

  span {
    color: white;
    font-size: 10px;
    font-weight: lighter;
    top: 4px;
    position: absolute;
    bottom: 0;
    display: block;
    margin: 0;
  }
}

.dealer-login {
  position: absolute;
  position: absolute;
  right: 0px;
  top: 47px;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0.5rem 1rem 0.25rem 1rem;
  display: none;

  label, input {
    font-size: 12px;
    margin: 0;
    min-width: 180px;
  }

  input {
    line-height: 1;
    margin-bottom: 0.25rem;
  }

  button {
    font-size: 12px;
    margin-top: 0.5rem;
    clear: both;
    float: left;
  }

  small {
    float: left;
  }
}

.login {

  label {
    font-weight: $site-lightfont;
  }

  small {
    float: left;
  }

  p, label {
    font-size: 12px;
  }

  small, p.error {
    margin: 0;
    color: red;
    margin-top: 0.25rem;
  }

  p.error {
    margin-bottom: 1rem;
  }

  .reset-pass-button {
    vertical-align: bottom;
    display: block;
    margin-top: 0.75rem;
    font-size: 11px;
  }

}

.order-review {
  background-color: white;
  padding: 1rem;
  overflow: hidden;
  position: relative;

  h1 {
    padding-left: 0;
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  h3:last-of-type {
    margin-bottom: 10px;
  }

  p:last-of-type {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 0px;
  }

  img {
    max-width: 100px;
  }

  table {
    font-size: 12px;

    td {
      padding: 10px;

      vertical-align: middle;
    }

    tbody, td {
      border: none;
    }
  }

  .brand-header {
    font-weight: bold;
    background-color: #D5E6F5;
    margin: 1rem 0px 0px 0px;

    span {
      float: right;
      background: url('../img/arrow.png');
      width: 20px;
      height: 13px;
      line-height: 1;
      background-size: 20px 13px;
      background-repeat: no-repeat;
      margin-top: 2px;
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      transform: rotate(180deg);

      &.open {
        transform: rotate(0deg);
      }
    }
  }

  .brand-items {
    margin: 0px 0px 2rem 0px;

    tr:first-of-type {
      background: aliceblue;
    }

    &:after {
      content: "-";
      display: block;
      line-height: 2rem;
      color: transparent;
    }
  }

  .total-discounts-header {
    background-color: #D8F5D5;
  }

  .total-discounts-items {
    tr {
      background-color: #F3FFF0 !important;
    }

    .brand-totals:last-of-type {
      background-color: #D8F5E0;
    }
  }

  .brand-totals {
    background-color: aliceblue;
    font-weight: bold;

    &:last-of-type {
      background-color: #DDEFFF;
    }
  }

  .final-totals {
    font-weight: bold;
    font-size: 12px;

    td:nth-of-type(n+2) {
      text-align: right;
    }

    td {
      border: none;
      padding: 5px 10px;
    }

    .discount-total {
      color: #3CC361;
    }

    .larger {
      height: 40px;
    }

    .total {
      font-size: 15px;
    }
  }

  .btn-primary {
    float: right;
  }

  span.order-number {
    font-size: 14px;
    position: absolute;
    right: 20px;
    display: block;
    top: 18px;
  }
}

.address-review {
  background-color: white;
  padding: 0px 1rem 1rem;
  overflow: hidden;

  h3 {
    font-size: 14px;
    color: $site-blue;
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0px;
    }
  }

  .address {
    font-size: 13px;
  }

  .btn {
    font-size: 12px;
  }

  .order-number {
    font-size: 14px;
    margin-top: 1rem;
  }
}

.product-variant-select {
  display: none;
}

/*Offers*/
.offer {
  width: 245px;
  height: 245px;
  background-color: $site-white;
  position: relative;
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;

  h3 {
    font-size: 14px;
    width: 90%;
    height: 56px;
    text-align: center;
    line-height: 1.35;
    margin-left: 5%;
    color: black;
    position: relative;
    z-index: 10;
  }

  a {
    text-decoration: none;
  }

  .image-container {
    width: 90%;
    height: 50%;
    left: 5%;
  }

  .btn {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    left: 50%;
    margin: 0px 0px 0.75rem -77.5px;
    text-align: center;
    width: 155px; /*TEMP TEMP*/
  }

}

/*SubCat Nav List*/

.subcat-nav {
  list-style: none;
  padding: 0;
  font-size: 14px;
  margin: 0px 0px 0.5rem 0px;

  a {
    color: $site-black;
  }

  li {
    margin-bottom: 0.25rem;
  }
  &.search-brands {
    li {
      margin-bottom: 0.5rem;
      font-size: 18px;
    }

  }
}
/* Hide Mobile Items */
.mobile-only {
  display: none;
}

@media (max-width: 1400px) {
  .hidden-1400-down {
    display: none;
  }
}

@media (max-width: 48em) {
  .expand-1400 {
    width: 83.333333%;
  }
}

@media (max-width: 768px) and (min-width: 651px) {
  /*.cart-button
	{
		width: 35px;
		color: transparent;
		background-image: url('../img/cart-black.png');
		background-repeat: no-repeat;
		background-size: 20px;
		background-position: 6px;
	}*/
}

@media (max-width: 540px) {

  .subcat-nav li {
    display: inline-block;
    width: 48%;
  }
}

/* ----------- iPhone 4 and 4S ----------- */
/* Mobile CSS */
@media (max-width: 650px) {

  #djDebug {
    display: none !important;
    visibility: hidden;
  }

  #top-nav {
    width: 100%;
    height: 35px;
    padding: 0;

    .navbar-brand {
      margin: 0px 0px 0px 1rem;
    }

    img {
      width: 90%;
    }

    #profile-nav {
      display: none;
    }
  }

  #bottom-nav {
    height: auto;
    padding: 0;

    .nav-link {
      height: auto;
      padding: 0 !important;
      margin: 0;
      display: table-cell;
      vertical-align: middle;
    }

    .nav-container > .row {
      margin: 0;

      & > div {
        padding: 0;
      }
    }

    .navbar-form, .cart-button {
      display: none;
    }

    .nav-item {
      width: 25%;
      text-align: center;
      display: table;
      height: 39px;
      margin: -4px 0px 0px 0px;

      &.mobile-only {
        margin-top: -5px;
      }

    }

  }

  .navbar .sub-nav {
    display: none;
  }

  /*Brands*/
  .brands-container .product-block {
    width: 125px;
    min-height: 125px !important;
    height: 100px !important;
  }

  /*Product Cat View*/

  .right-banner, .product-block .left-banner {
    display: none;
  }

  .product-block.list {
    height: 195px !important;
    width: 155px;
    min-height: initial;
    margin-right: 0.9rem;

    div.cost span.no-cost {
      font-size: 10px !important;
      width: 52px;
      display: block;
      margin-top: 0px;
    }

    .image-container {
      height: 43%;
    }

    h4 {
      font-size: 11px;
    }

    .info {
      height: 30px !important;
    }

    span.cost {
      font-size: 13px;
    }

    button {
      display: none;
    }

    div.cost {
      margin-top: 3px;
    }

    .white-bg {
      height: 142px;
    }
  }

  .product-list {

    .product-info {
      h4 {
        font-size: 12px;
      }

      p {
        font-size: 10px;
      }

      span.cost {
        font-size: 16px;
      }

    }
  }

  /*OFFERS*/
  .offer {
    width: 100%;

    h3 {
      font-size: 14px;
    }
  }

  /*Product View*/
  .product-container .header {
    display: flex;
    flex-flow: column-reverse;
  }
  .product-container .col-md-6.col-lg-5 {
    min-width: auto !important;
  }

  .product-theatre {
    display: none;
  }

  .lightbox {
    width: 75%;
    left: -5%;
  }

  /*NAV*/
  .sub-sub-nav-container {
    top: 29px !important;
    margin-left: 0 !important;
    width: 100%;

    .col {
      width: 50%;
      margin-bottom: 1rem;
    }
  }

  #brands-nav {
    height: auto;
  }

  /*Account*/
  /*.tab-content
	{
	    overflow: auto;
	    font-size: 10px;
	}

   	.account
   	{
   		table td
   		{
   			min-width: 130px;
   			font-size: 12px;
   		}

	   	h6, p
	   	{
	   		font-size: 12px;
	   	}

	   	.nav-tabs
	   	{
			font-size: 12px;
			font-weight: bold;

		    li
		    {
		    	padding: 0.5rem 0.15rem 0.4rem 0.15rem;
		    }
	   	}
	}*/

  /*Cart*/
  .cart {
    .brand {
      padding: 0;
      border: none;
      background: transparent;
      margin-top: 1rem;

      .brand-item {
        border-color: #95C7EF;

        .item-info p {
          font-size: 13px;
        }
      }
    }

    .discounts, .partial-discounts {

      .brand-item {
        border: rgba(155, 206, 160, 0.65) solid 1px;

        .item-info {

          input {
            margin-left: 0px;
            clear: left;
          }

          span {
            margin-bottom: 0.25rem;

            &:nth-of-type(2n) {
              display: none;
            }
          }

        }
      }

    }

    .partial-discounts {
      span {
        display: none !important;
      }

      .btn {
        float: left;
        clear: left;
      }
    }

    .brand-totals {
      span {
        width: 100%;

        strong {
          float: right;
        }
      }
    }

    .freight {
      width: 100%;
      margin-bottom: 20px;
    }

    .cart-totals span {
      width: 100%;

      strong {
        float: right;
      }

    }
  }

}

/* ----------- iPhone 5 and 5S ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {

  #djDebug {
    opacity: 0;
  }

}

/* ----------- iPhone 6 ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {

  #djDebug {
    opacity: 0;
  }

}

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {

}

/*NEW NAV*/
.new-nav-wrapper {
  background-color: white;
  border-bottom: $site-middlegrey solid 1px;
}

.new-nav-bar {
  max-width: 91.875rem;
  margin: 0 auto;
  overflow: hidden;
  padding: 0px 0.9375rem;

  a:hover, a:focus {
    text-decoration: none;
  }

}

.new-nav-bar ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.new-nav-bar a {
  color: inherit;
}

.new-nav-sale-items a, .new-nav-info-items a {
  padding: 10px;
  display: block;
}

.new-nav-controls {
  height: 35px;
  margin-top: 5px;
}

.new-logo {
  width: 20%;
  overflow: hidden;
  height: 80%;
  float: left;
  margin-top: 5px;
}

@media only screen and (min-width: 1410px) {
  .new-logo {
    height: 200%;
    padding: 10px;
  }
}

.new-logo img {
  height: 100%;
  float: left;
  object-fit: contain;
}

.new-nav-controls form {
  display: block;
  position: relative;
  max-width: 650px;
  width: 40%;
  float: left;
  margin: 3px 10px 0px 10px;
  overflow: hidden;
}

.new-nav-controls input {
  float: left;
  background-color: #F0FAFF;
  border: solid 1px #b1b3b5;
  width: 100%;
  font-size: 12px;
  height: 30px;
  margin: 0px;
  padding: 0px 0px 0px 5px;
  border-right: none;
}

.new-nav-controls button[type="submit"] {
  background-color: #3197EC;
  font-size: 12px;
  border: none;
  color: white;
  height: 30px;
  font-weight: 600;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 0;
}

.cart-button {
  display: none;
}

.cart-button, .dealer-login-button, .mobile-cart-button, .become-a-reseller-button {
  background-color: #3197EC;
  border: none;
  color: white;
  margin: -5px 0px 0px 0px;
  float: right;
  height: 37px;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 700;
  overflow: hidden;

  //span
  //{
  //	width: 20px;
  //	height: 20px;
  //	background-image: url('../img/cart-black.png');
  //	display: block;
  //	background-size: 20px;
  //	float: left;
  //	margin-right: 8px;
  //}
}

.mobile-cart-button {
  display: none;
}

.dealer-login-button {
  padding: 0px 12px;
  font-size: 15px;
  line-height: 15px;
}

.become-a-reseller-button {
  margin-right: 25px;
  background: #fd2f40;
  font-size: 15px;
  line-height: 15px;
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 1800% 1800%;
  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }
  50% {
    background-position: 100% 19%
  }
  100% {
    background-position: 0% 82%
  }
}

@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }
  50% {
    background-position: 100% 19%
  }
  100% {
    background-position: 0% 82%
  }
}

@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%
  }
  50% {
    background-position: 100% 19%
  }
  100% {
    background-position: 0% 82%
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%
  }
  50% {
    background-position: 100% 19%
  }
  100% {
    background-position: 0% 82%
  }
}

.new-nav-sale-items {
  margin-top: 8px;
  display: inline-block;
  float: left;
}

.new-nav-sale-items li {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

.new-nav-sale-items li:hover {
  background-color: #3197EC;
  cursor: pointer;
  color: white;
}

.new-nav-special {
  color: red;
}
.new-nav-open-box {
  color: #1068b3;
}

li.new-nav-clearance-corner {
  color: #ff0208;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: #ff0208;
  }
}

.new-nav-sale-items li.new-nav-special:hover {
  color: white;
  cursor: pointer;
  background-color: red;
}

.new-nav-info-items {
  margin-top: 13px;
  float: right;
  margin-left: -10px;

  ul {
    height: 100%;
  }

  li {

    display: inline-block;
    font-size: 11px;
    float: right;
    height: 100%;
    vertical-align: middle;
    font-weight: bold;
    text-decoration: none !important;

    &:hover {
      background-color: $site-blue;
      color: $site-white;
    }
  }
}

.new-nav-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

/*Sub Nav CSS*/
.new-sub-nav-wrapper {
  max-width: 91.875rem;
  margin: 0 auto;
  position: relative;
  padding: 0px 5px 0px 4px;
}

#sub-nav-2 {
  left: 161px;
  padding: 10px 0px 0px 10px;
}

#sub-nav-2 .new-sidebar-menu {
  float: left;
  min-width: 500px;
}

.new-sub-nav-sidebar {
  max-width: 350px;
  height: auto;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  z-index: 100;
  padding: 10px 15px 0px 10px;
  border: 1.5px solid #CDCDCD;
  display: none;
}

.new-sidebar-menu {
  list-style: none;
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #383838;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
}

.level-1 li span {
  font-weight: bold !important;
}

.new-sidebar-menu li {
  height: 40px;
  cursor: pointer;
}

.new-sidebar-menu li span {
  margin-right: 20px;
  transition: margin-right 0.5s;
}

.new-sidebar-menu li a, .new-sidebar-menu a {
  color: inherit;
  text-decoration: none;
  height: 30px;
  display: block;
}

.new-nav-products-menu {
  padding-right: 10px;
}

.new-sidebar-menu li.active, .new-sidebar-menu li:hover {
  color: #3197EC;
}

.new-nav-sub-products-menu-wrapper {
  left: 176px;
  position: absolute;
  width: 0;
  height: 400px;
  overflow: hidden;
}

.new-nav-sub-products-menu {
  padding-right: 10px;
  border-left: 1.5px solid #EAE8E8;
  overflow: hidden;
  white-space: nowrap;
}

.arrow {
  float: right;
  margin-top: 3px;
  transition: margin-right 0.5s;
}

.new-sidebar-menu li.active span {
  margin-right: 15px;
}

.new-sidebar-menu li.active .arrow {
  margin-right: 5px;
}

.new-nav-see-all {
  color: #3197EC;
  margin-bottom: 10px;
  display: block;
  font-size: 11px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

.new-account-nav-wrapper {
  max-width: 91.875rem;
  margin: 0 auto;
  position: relative;
  padding: 0px 0.9375rem;
}

.new-account-nav {
  font-size: 10px;
  position: absolute;
  right: 0;
  background-color: #3197EC;
  display: none;
  z-index: 1000;
  padding: 10px 20px;
  margin-right: 15px;
  top: -1px;

  & > span {
    float: left;
    font-size: 14px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    color: white;
  }

  & > span:last-of-type {
    clear: left;
    font-size: 12px;
  }

  .icon-container {
    overflow: hidden;
    float: left;
    width: 190px;
    clear: left;
  }

  .icon-container a {
    color: inherit !important;
  }

  .icon-button {
    width: 90px;
    float: left;
    height: 90px;
    margin-bottom: 5px;
    display: table;
    position: relative;

    span {
      display: table-cell;
      vertical-align: bottom;
      padding-left: 3px;
      text-align: center;
    }

    div {
      width: 85px;
      height: 85px;
      position: absolute;
      background-size: 52px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .icon-button:nth-of-type(even) {
    margin-left: 10px;
  }

  #personal div {
    background-image: url('../img/profile-icons/personal.png');
  }

  #store div {
    background-image: url('../img/profile-icons/store.png');
  }

  #address div {
    background-image: url('../img/profile-icons/address.png');
  }

  #password div {
    background-image: url('../img/profile-icons/password.png');
  }

  #logout div {
    background-image: url('../img/profile-icons/logout.png');
  }
}

.new-account-nav ul {
  list-style: none;
  font-size: 12px;
  color: #0C0B0B;
  display: block;
  float: left;
  clear: left;
}

.new-account-nav li {
  text-align: left;
  margin-top: 7px;

  a {
    color: inherit;

    &:hover {
      color: white;
    }
  }

  i {
    margin-right: 10px;
    text-align: center;
    width: 10px;
  }
}

.account-button, .mobile-account-button {
  cursor: pointer;
  color: $site-blue;

  &.active {
    color: white;
    background-color: $site-blue;
  }
}

.mobile-account-button {
  display: none !important;
}

.new-account-divider {
  margin: 0.5rem 0px;
  border: 0;
  border-top: solid 1px rgba(255, 255, 255, 0.5);
}

.product-eol-variant-banner {
  display: block;
  position: absolute;
  top: -17px;
  background: red;
  width: 70px;
  padding: 0 0.5rem;
  border: solid 1px red;
  color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  font-size: 10px;
  opacity: 0.3;
}

.product-eol-variant-banner.spo {
  background-color: orange;
}

.product-eol-variant-banner.active {
  opacity: 1;
}

._2018-product-variants {
  overflow: hidden;

  .variant-container {
    display: block;
    color: #3197ec;
    background: white;
    border: solid 1px #3197ec;
    text-align: center;
    min-height: 35px;
    min-width: 70px;
    word-wrap: break-word;
    max-width: 90px;
    line-height: 1.35;
    padding: 0rem 0.5rem;
    float: left;
    position: relative;
    margin: 0px 5px 5px 0px;
    box-sizing: content-box;

    a {
      color: inherit;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-decoration: none !important;
    }

    &.active {
      background: #3197ec;
      color: white;

      .eol-banner {
        opacity: 1 !important;
      }
    }

    .eol-banner {
      display: block;
      position: absolute;
      top: -16px;
      left: -1px;
      background: #f5140d;
      width: calc(100% + 2px);
      padding: 0 0.5rem;
      border: solid 1px transparent;
      color: white;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      text-align: center;
      font-size: 10px;
      opacity: 0.3;
      box-sizing: border-box;

      &.so {
        background: #fb862c;
        opacity: 0.4;
      }

      &.cc {
        background: #fb862c;
        opacity: 0.4;
        white-space: nowrap;
      }
    }
  }

}

/*.product-eol-wrapper
{
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: -37px;
    overflow: hidden;
    display: none;
}

.product-eol
{
	position: absolute;
    z-index: 15;
    transform: rotate(31deg);
    background: red;
    font-size: 16px;
    top: 33px;
    right: -64px;
    color: white;
    padding: 0px 85px;

    span
    {
    	text-align: center;
    }
}
*/
.row.header {
  position: relative;
}

div.load-fail {
  height: 100px;
  display: block;
  background-image: url('../img/error.png');
  width: 100px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: none;
}

p.load-fail {
  display: none;
  color: red;
}

img {
  max-width: 100%;
}

input.error {
  border: solid 1px rgb(255, 0, 0);
}

.carousel-indicators li {
  background-color: $site-lightgrey;
}

.tt-suggestion {
  background-color: white;
}

.quick-add-button, .quick-add-button-mobile {
  float: left;

  button {
    font-size: 12px;
    width: auto !important;
    margin: 0 !important;
  }
}

.quick-add-button-mobile {
  display: none;
  float: right;
  margin-top: 27px;
}

.quick-add-form {
  .tt-suggestion {

    font-size: 12px;
    margin-bottom: 5px;
    border-bottom: solid 1px lightgrey;
    padding-bottom: 5px;

    &:last-of-type {
      border-bottom: none;
    }

    div {
      display: table-cell;
      vertical-align: middle;
      min-width: 70px;

      span {
        margin-left: 10px;
        display: block;
      }
    }

    img {
      margin-left: 5px;
    }

    &:hover {
      color: #66AFE9;
      cursor: pointer;
    }
  }

  .tt-menu {
    width: 100%;
    top: 93% !important;
  }

  .tt-dataset {
    overflow: hidden;
    background: white;
    border: solid 1px #66afe9;
    padding-top: 5px;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
}

.print-info-button {
  font-size: 12px;
  width: auto;
  position: absolute;
  padding: 5px 10px;
  top: 0;
  right: 15px;
  z-index: 15;
}

.print-form {
  font-size: 14px;

  label {
    float: left;
    margin: 0;
    font-weight: bold;

    &:after {
      content: ':';
    }
  }

  #id_address {
    float: left;
    width: auto;
    border-radius: 0px;
    margin-left: 30px;
    color: #353535;
    border: #3197EC solid 1px;
    font-size: 13px;
  }

  #id_variants {
    list-style: none;
    font-size: 14px;
    padding: 0;

    li {
      display: block;
      overflow: hidden;
      float: left;
      clear: left;

      input {
        float: left;
        width: auto;
        margin-top: 3px;
        margin-right: 5px;
      }

      label {
        float: left;
        margin: 7px 0px 2px 0px;
        font-weight: normal;
        font-size: 13px;

        &:after {
          content: none;
        }
      }
    }
  }

  #id_sales_person {
    width: auto;
    margin: 4px 0px 0px 20px;
    display: inline;
  }

  .btn {
    width: auto;
    font-size: 12px;
    float: left;
    clear: left;
  }

  .print-title {
    font-size: 14px;
    font-weight: bold;
    float: left;
    clear: left;
    margin-bottom: 0.5rem;
  }

  .print-select, .print-check {
    width: 22px;
    height: 22px;
    float: left;
    clear: left;
    display: table;
    margin-right: 20px;
    border: solid 1px #B7B7B7;
    margin-bottom: 10px;
    color: $site-blue;
    cursor: pointer;

    i {
      width: 100%;
      display: block;
      text-align: center;
      height: 100%;
      vertical-align: middle;
      font-size: 16px;
      display: table-cell;
    }
  }

  span {
    float: left;
  }

  .print-hide-form {
    display: none;
  }
}

.secure-checkout {
  font-size: 16px;
  padding: 10px 0px 5px 0px;
  font-weight: bold;
  margin: 0;
  -webkit-font-smoothing: antialiased;

  &::before {
    content: "";
    float: left;
    width: 18px;
    height: 18px;
    margin: 2px 8px 0 0;
    background-repeat: no-repeat;
    background-size: 18px;
    background-image: url('../img/profile-icons/password.png');
  }
}

.expand-image-buton {
  background-image: url('../img/icons/expand.png');
  width: 30px;
  height: 25px;
  background-size: 30px;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  right: 15px;
  opacity: 0.3;
  cursor: pointer;

  &:hover, &.active {
    opacity: 0.6;
  }
}

.info-box {
  position: absolute;
  padding: 0 !important;
  //width: 400px;
}

.info-box-container {
  position: relative;
  margin-top: 20px;
  height: 330px;
  overflow: hidden;
  padding-left: 12px;
  background: #E5F3FD;
  margin-bottom: 10px;
}

.info-box-nav-button {
  background-image: url('../img/icons/mobile-menu.png');
  max-width: 30px;
  height: 25px;
  margin-top: 7px;
  background-size: 27px;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
  cursor: pointer;
  width: 90%;
  position: absolute;
}

.product-availability-table {
  width: 100%;

  th, td {
    text-align: center;
  }

  thead {
    width: 100%;

    tr:first-child {
      th:first-child {
        text-transform: uppercase;
        color: #999999;
        font-weight: lighter;
        letter-spacing: 2px;
      }
    }
  }
}

.new-product-container {
  background-color: white;
  padding: 0.975rem;
  overflow: hidden;
  border: $site-lightgrey solid 0.1px;

  .new-product-heading {

    width: 100%;
    float: left;
    clear: left;
    position: relative;

    h1 {
      font-size: 18px;
      font-weight: lighter;
      padding: 0;
      float: left;
    }

    .uom-title {
      float: left;
      clear: left;
      font-size: 10px;
      margin-top: -5px;
      margin-bottom: 2px;
    }

    .product-top-toolbar {
      position: absolute;
      right: 0;
      top: -6px;
      .btn {
        font-size: 12px;
      }
    }
  }

  .new-product-buying-info {
    float: left;
    margin-top: 10px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: stretch;
    

    /*.new-product-info-box-wrapper {
      width: 36%;
      float: left;
      position: relative;
      height: 320px;
      padding: 0px;
      overflow: hidden;
      box-sizing: border-box;
    }

    .new-product-info-box {
      width: 100%;
      position: absolute;
      height: 100%;
      background: aliceblue;
      padding: 5px;
      box-sizing: content-box;
    }*/

    .new-product-info-box-wrapper
    {
        width: 36%;
        float: left;
        min-height: 350px;
    }

    .new-product-info-box
    {
      overflow: auto;
      background: aliceblue;
      padding: 5px;
      box-sizing: border-box;
    }

    /*.new-product-gallery {
      width: 60%;
      float: right;
      height: 350px;
    }*/

    .new-product-gallery-wrapper
    {
      position: absolute;
      width: 62%;
      right: 0;
      background: white;
      height: 100%;
      min-height: 350px;
    }

    .new-product-gallery
    {
      width: 100%;
     height: 100%;
    }


    .new-product-gallery-image-container {
      height: 80%;
      width: 100%;
      float: left;
      position: relative;
    }

    .new-product-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      display: none;
    }

    .new-product-gallery-thumbs-container {
      width: 80%;
      float: left;
      text-align: center;
      height: 40px;
      margin-left: 10%;
      margin-top: 3%;
      position: relative;
      z-index: 4;
    }

    .new-product-gallery-thumbs {
      margin: 0 auto;
      width: auto;
      display: inline-block;
    }

    .thumb {
      width: 60px;
      height: 40px;
      width: 40px;
      background-size: 40px;
      background-repeat: no-repeat;
      background-position: center;
      float: left;
      margin: 0px 5px 5px 5px;
      cursor: pointer;
    }

    .new-product-image-expand-button {
      height: 40px;
      float: right;
      width: 10%;
      margin-top: 3%;
      opacity: 0.3;
      background-image: url('../img/icons/expand.png');
      background-size: 30px;
      background-repeat: no-repeat;
      cursor: pointer;
      background-position: right;

      &:hover {
        opacity: 0.6;
      }

    }

    .new-product-variants {
      margin-top: 10px;
      font-size: 12px;
      /*overflow: hidden;*/

      &.eol-padding {
        padding-top: 12px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      li {
        position: relative;

        button {
          margin: 0px 5px 5px 0px;
          background-color: #fff;
          color: #3197EC;
          border: 1px solid #3197EC;
          min-width: 70px;
          word-wrap: break-word;
          max-width: 90px;
          line-height: 1.35;
          padding: 0rem 0.5rem;
          min-height: 34px;
          float: left;
          outline: none;

          &.active {
            color: white;
            background: #3197EC;
          }

          &:disabled {
            /*border-color: #d4e8f9;
						background-color: #a9c8e2;
						color: #e8e7e7;*/
            opacity: 0.25;
            cursor: not-allowed;
          }
        }

        &.active {
          color: white;
          background: #3197EC;
        }
      }

      .variant-selectors-eol {
        color: black;
        margin: 7px 0px;
        display: none;

        span {
          padding: 3px 6px;
          display: inline-block;
          background: red;
          border-radius: 2px;
          color: white;
          margin-left: 5px;
        }
      }

    }

    .new-product-availability {
      float: left;
      margin: 10px 0px 0px 1.5%;
      width: calc(100% - 20px);

      .availability-title {
        font-size: 12px;
        text-transform: uppercase;
        float: left;
        margin-bottom: 5px;
        opacity: 0.9;
        letter-spacing: 1.2px;
      }

      .availability-var {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .stock {
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: start;
          padding: 7px 8px;
          text-align: center;
          align-items: center;
        }

        & > div {
          width: 45%;
          float: left;

          &:first-of-type {
            clear: left;
            text-align: center;
          }

          span:first-of-type {
            font-weight: bold;
            text-align: center;
            font-size: 12px;
            float: left;
          }
        }
      }

      .label {
        font-size: 10px;
        font-weight: bold;
        float: left;
        clear: left;
        /*height: 11px;*/
      }

      .est-stock {
        margin-top: 0px;
        font-size: 10px;
        float: left;
        font-weight: normal;
        text-align: center;
        width: 100px;
        display: block;
        padding: 10px 0px 0px 0px;
        color: #818A9C;

        &.no-stock {
          font-weight: bold;
          padding: 2px 0 0 0;
        }

        strong {
          color: $site-blue;
        }
      }

      .no-est-stock {
        margin-top: 0px;
        font-size: 8px;
        float: left;
        font-weight: lighter;
        text-align: center;
        //width: 80px;
        display: block;
        padding: 2px 0px 0px 0px;
        color: #818A9C;
      }

      .est-stock-padding {
        margin-bottom: 30px;
      }

      .spo-order-eta {
        display: block;
        font-size: 11px;
        float: left;
        width: 90%;
        margin-top: 6px;
        margin-bottom: 15px;
      }

    }

    .new-price {
      margin-left: 1.5%;
      overflow: hidden;
      float: left;
      clear: left;
      width:98.5%;

      .cost-flex-container {
        display: flex;
        justify-content: space-between;
        width: 94%;
        align-items: baseline;
      }

      .go-price {
        //display: none;
      }

      .go-price.force_hide {
        display: none !important;
      }

      .gst, .go_price_gst {
        font-size: 8px;
        line-height: 1;
        float: left;
        margin: 14px 0px 0px 3px;
      }

      .go_price_gst {
        margin-top: 9px;
      }

      button {
        font-size: 9px;
        padding: .175rem 0.5rem;
        clear: left;
        float: left;
        margin-top: 15px;
        margin-bottom: 25px;
        outline: none;
      }

      .dealer-price {
        display: none;
        clear: left;

        div.cost {
          clear: none;
          margin-right: 20px;
        }

        span.cost {
          font-size: 16px !important;
          color: inherit;
        }
      }

      .no-auth, .not-available {
        font-size: 10px;
        float: left;
        margin-top: 10px;

        span {
          display: block;
        }
      }

      .not-available {
        display: none;
      }
    }

    .whole-product-eol-box {
      background: red;
      float: left;
      margin: 10px 0px 5px 1.5%;
      padding: 3px 8px;
      border-radius: 3px;
      font-size: 12px;
      -webkit-font-smoothing: antialiased;
      color: white;
      font-weight: bold;
      display: inline-block;
    }

    /* div.add-to-cart {
      overflow: hidden;
      float: left;
      clear: left;
      width: 100%;
      position: absolute;
      bottom: 20px;
      left: -5px;

      button {
        margin: 0;
        width: 90%;
        box-sizing: border-box;
        margin: 0 auto;
      }
    } */

    div.add-to-cart
    {
      float: left;
      clear: left;
      margin-bottom: 10px;
      width: 100%;

      button
      {
        width: 95%;
        box-sizing: border-box;
        margin: 0 auto;
      }
    }

  }

  .product-row {
    margin-top: 25px;

    .product-under-box {
      margin-top: 10px;
      position: relative;
      z-index: 1;
      background: aliceblue;
      display: inline-block;
      padding: 10px 11px;
      font-size: 13px;
      -webkit-font-smoothing: antialiased;
      color: #444444;
      width: 100%;
      word-wrap: break-word;

      a {
        display: inline-block;
        font-size: 14px;
      }

      h1 {
        font-weight: bold;
      }

      h2 {
        font-size: 14px;
      }
    }

  }

  .superseded-box {
    margin-bottom: 240px;
    position: relative;
    background: aliceblue;
    display: inline-block;
    padding: 5px 11px;
    font-size: 13px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    color: #444444;

    a {
      display: inline-block;
      font-size: 14px;
    }
  }

  .product-detail {
    margin-top: 25px;

    .nav-tabs {
      margin: 0;
      padding: 0;
      border: 0;
      position: relative;
    }

    .nav-tabs a {
      display: block;
      float: left;
      background-color: #7AC2FF;
      padding: .175rem .5rem;
      font-size: 12px;
      color: white;
      -webkit-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
      -moz-box-shadow: 3px 4px 5px 0 rgba(0, 0, 0, .75);
      box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .75);
      margin: 15px 15px 0px 0px;

      &:hover {
        text-decoration: none !important;
        color: #FFFFFF;
        background-color: #3CA6FF;
      }

      &.active {
        text-decoration: none !important;
        color: #FFFFFF;
        background-color: #3CA6FF;
      }

    }

    .tab-content {
      min-height: 1000px;
    }

    #description {
      margin-top: 10px;

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1rem;
        font-weight: lighter;
      }

      h1 {
        font-size: 18px;
      }

      h2 {
        font-size: 16px;
      }

      h3 {
        font-size: 15px;
      }

      h4 {
        font-size: 14px;
      }

      h5 {
        font-size: 12px;
      }

      h6 {
        font-size: 12px;
      }

      p, ul {
        font-size: 12px;
        width: auto;
        margin: 0px 0px 1rem 0px;
      }

    }

    .tab-pane {
      border-radius: 0;
      background-color: inherit;
      position: static;
      overflow-x: hidden;

      .row {
        margin-top: 10px;
      }

      .spec {
        font-size: 12px;
        font-weight: lighter;
        color: $site-darkgrey;

        p {
          margin: 1px 0px 0px 0px;

          sup {
            top: -0.1rem;
          }
        }

        span {
          margin-top: 10px;
          display: block;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }

      .resources-container-flex {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;

        .resource {
          width: 50%;
          display: block;

          a:last-of-type {
            display: flex;
            align-items: center;
            height: 100%;
          }
        }

        .resource-file, .resource-file-pdf, .resource-file-img {
          width: 25px;
          height: 35px;
          background-repeat: no-repeat;
          background-size: 25px;
          float: left;
          margin-right: 5px;
          background-image: url('../img/file.png');
          margin-bottom: 15px;
        }

        .resource-file-pdf {
          background-image: url('../img/pdf.png');
        }

        .resource-file-img {
          background-image: url('../img/img.png');
        }

        .resource-name {
          margin: 0px 25px 15px 0px;
          display: block;
          color: #373a3c;
          font-size: 12px;
        }
      }

      .resources-container {
        font-size: 12px;
        margin-top: 5px;

        a {
          text-decoration: none;
        }

        .resource-file, .resource-file-pdf, .resource-file-img {
          width: 25px;
          height: 35px;
          background-repeat: no-repeat;
          background-size: 25px;
          float: left;
          margin-right: 5px;
          background-image: url('../img/file.png');
          margin-bottom: 15px;
        }

        .resource-file-pdf {
          background-image: url('../img/pdf.png');
        }

        .resource-file-img {
          background-image: url('../img/img.png');
        }

        .resource-name {
          margin: 0px 25px 15px 0px;
          display: block;
          color: #373a3c;
        }
      }

      .media {
        margin-top: 30px;
        color: $site-darkgrey;
        font-size: 12px;
        float: left;
        clear: left;
        width: 95%;

        span {
          float: right;
        }

        p, a {
          width: auto;
          float: left;
          clear: left;
          margin: 0;
        }

        .media-body a {
          color: $site-black;
          margin-top: 5px;
        }
      }

      .media-heading {
        font-size: 14px;
        display: block;
        float: left;
        font-weight: bold;
        color: $site-black;
      }

    }

  }

  .info-icons {
    margin-right: auto;
    background-color: #E5F3FD;
    border: solid 1px #C6E3F9;
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin: .9375rem;

    td {
      padding: 0.75rem 0.3rem 0;
    }

    .icon {
      width: 50px;
      height: 40px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    p {
      height: auto;
      font-size: 12px;
      margin: 0;
    }

  }

  .product-theatre {
    margin-top: 40px;

    img {
      max-width: 100%;
    }

    h6 {
      font-weight: $site-lightfont;
      font-size: 23px;
      color: $site-darkgrey;
      -webkit-font-smoothing: antialiased;
    }
  }
}

/*New Account */
.new-account-detail-nav {
  width: 100%;
  color: black;
  margin-top: 0.5rem;
}

.new-account-detail-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: solid 1px #BBB;
}

.new-account-detail-nav ul li.active {
  color: #3197EC;
}

.new-account-detail-nav ul li {
  display: inline-block;
  padding: 5px 20px 5px 0px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  font-size: 14px;
}

.new-account-detail-nav ul li:last-of-type {
  padding-right: 0;
}

.account-content {
  font-size: 12px;
  padding: 0.5rem 0.975rem;
  overflow: hidden;
  position: relative;
}

.account-body {
  display: none;
  position: absolute;
}

.account-body td {
  vertical-align: top;
}

.account-body td:nth-of-type(2n), .account-body a {
  font-weight: bold;
}

.staff td span {
  font-weight: normal;
  padding: 0;
  font-size: 12px;
  margin: 5px;
}

.staff td:first-of-type span {
  font-weight: bold;
}

.staff tr {
  background: rgba(255, 255, 255, 0.2);
}

.staff tr:nth-of-type(2n) {
  background: rgba(255, 255, 255, 0.31);
}

.staff td:first-of-type {
  padding-left: 0.975rem;
}

.staff td:nth-of-type(n+5) {
  text-align: center;
}

.staff input {
  font-size: 12px;
  color: white;
  font-weight: bold;
  border: none;
  background: #3197EC;
  -webkit-font-smoothing: antialiased;
}

.staff .label {
  display: inline;
  text-align: center;
}

.account-body .progress {
  margin: 7px 0px 0px 0px;
}

.account-body span, .account-body a {
  display: block;
  padding: 5px 5px 5px 0px;
}

.account-body button {
  font-size: 12px;
}

.account-body .btn-group {
  font-size: 12px;
  margin: 5px;
}

.account-body .dropdown-item {
  font-weight: normal;
  font-size: 12px;
  padding: 0px 10px;
}

.staff td {
  vertical-align: middle;
}

#address-panel table, .account-title {
  margin-bottom: 1rem !important;
}

#address-panel th, #address-panel td {
  padding: 0px 10px;
}

#address-panel th:first-of-type, #address-panel td:first-of-type {
  padding-left: 0;
}

#address-panel td {
  font-weight: normal;
}

#address-panel span a {
  display: inline;
  padding: 0;
}

.s-account-container {
  background: white;
  overflow: hidden;
  padding: 0.9375rem;

  h1, h3, h6, p {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 18px;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  h3 {
    font-size: 15px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  ul {
    margin: 0;
    padding-top: 0;
    list-style: none;
  }

  h6 {
    font-size: 13px;
    margin-top: 0.75rem;
    margin-bottom: 0.2rem;
  }

  li, p, span, th {
    font-size: 12px;
  }
}

.s-account-icon {
  background: no-repeat url('../img/account.png');
  background-size: 140px;
  width: 140px;
  height: 140px;
  margin: 0px auto;
}

.s-account-actions {
  margin-bottom: 2rem;
  float: left;
  width: 100%;
  clear: left;

  button {
    i {
      margin-right: 5px;
    }

    font-size: 13px;
    float: left;
    margin-right: 10px;
    background-color: transparent;
    border-color: #AFA5A5;
  }
}

.s-account-content {
  display: none;

  td, th {
    padding: 0.25rem 0.2rem;
  }

  .progress {
    max-width: 80%;
    float: left;
    margin-top: 1rem;
  }

  .credit {
    float: left;
    display: inline;
    margin-right: 1rem;
    margin-top: 1rem;
  }

  .staff-table {
    margin-left: 2rem;
  }

  button {
    font-size: 12px;
  }

  .btn-group {
    font-size: 12px;
    margin: 5px;
  }

  .dropdown-item {
    font-weight: normal;
    font-size: 12px;
    padding: 0px 10px;

    span {
      padding: 5px;
      display: inline-block;
    }
  }

  .address, .representative-table {
    td, th {
      padding: 0.25rem 1rem 0.25rem 0px;
    }

    margin-bottom: 1rem;
  }

  .s-contact {
    margin-top: 1rem;
  }

  span.active {
    color: green;
  }

  span.inactive {
    color: red;
  }

  .representative-table {
    p.active {
      color: green;
    }

    button {
      padding: 3px 8px !important;
    }
  }

  &#representatives {
    select, label {
      display: none;
    }
  }
}

.s-account-logout-button {
  font-size: 11px;
  position: absolute;
  top: 0.5rem;
  right: 0.9735rem;
  margin-right: 0.5rem;
  border: solid 1px #AFA5A5;
  background: white;
  padding: 4px 6px;
  color: #6B6767;
  display: none;
}

@media (max-device-width: 736px) {
  .new-product-container .new-product-buying-info .new-product-info-box-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

 /* .new-product-container .new-product-buying-info .new-product-gallery {
    width: 100%;
    max-height: 250px;
  }*/

  .new-product-image-expand-button, .product-top-toolbar {
    display: none;
  }

  .breadcrumb li {
    font-size: 8px !important;
  }
}

/* ----------- iPhone 6 ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {

  .specials {
    height: 500px;

    .offer {
      width: 100%;
    }
  }

}

/*#djDebug
{
	display: none !important;
	visibility: hidden;
}*/

/*MOBILE CSS WITH THE BOOTSTRAP CLASSES*/

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {

  /*body
	{
		background: orange;
	}*/

}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  /*
	body
	{
		background: pink;
	}*/

  .new-nav-info-items {

    ul {
      float: left;
    }

  }

  .new-nav-sale-items {
    margin-top: 8px;
  }

  .new-nav-bottom {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .view-container {
    float: left;
    margin-top: 11px;
  }

  /*Cart*/
  .cart .brand-item .item-info {
    max-width: 80%;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {

  /*body
	{
		background: green;
	}*/

  .cart-button {
    display: none !important;
  }

  .mobile-cart-button, .mobile-account-button {
    display: block !important;
  }

  .new-sub-nav-sidebar {
    background: white;
  }

  #brand-detail-nav {
    min-width: 0;
  }

  .new-nav-controls form button {
    padding: 0px 11px;

    span {
      display: none;
    }
  }

  /*Product Detail*/
  .new-print-info-button {
    display: none;
  }

  .new-product-container .new-product-buying-info .new-product-info-box-wrapper
  {
    width: 100%;
  }

  .new-product-buying-info
  {
    flex-wrap: wrap;
  }

  .new-product-container .new-product-buying-info .new-product-gallery-wrapper
  {
    position: relative;
    height: 250px;
    width: 100% !important;
  }

  .new-product-container .new-product-buying-info .new-product-image-expand-button {
    display: none;
  }

  /*INDEX*/
  .specials {
    margin-bottom: 20px;
  }

  .home .specials {

    .offer {
      height: 218px !important;
    }

  }

  /*Cart*/
  .cart .brand-item .image-container {
    display: none;
  }

  .clear-cart-button {
    float: left;
    clear: both;
    margin-top: 30px;
  }

  .quick-add-button-mobile {
    display: block;
  }

  .quick-add-button {
    display: none;
  }

  .discounts .item-info p {
    margin-bottom: 5px !important;
  }

  .discounts .item-total span:first-of-type {
    font-size: 10px !important;
  }

  /*Address Selection*/
  .order-summary {
    margin-top: 20px;
    padding-top: 0.9375rem;
  }

  .address-selection .freight, .address-selection .dropship {
    width: 100% !important;
    float: left;
    margin-bottom: 10px;
  }

  .address {
    clear: both;
  }

  /*Order Review*/

  .order-review {
    table:first-of-type tr:nth-of-type(n+2) td:first-of-type {
      padding: 0;
      margin: 0px;

      img {
        display: none;
      }
    }

    .extended-terms {
      margin: 5px 0px 0px 0px;
    }

    span.order-number {
      position: static;
      margin-top: 12px;
      display: block;
    }
  }

  /*Order Success*/
  .address-review {
    margin-top: 1rem;
  }

  /* Account */
  .new-account-detail-nav ul li {
    font-size: 12px !important;
  }

  .new-account-detail-nav .mobile-only {
    display: block;
  }

  .account-content {
    font-size: 10px !important;
  }

  /*Footer*/
  footer {
    position: static;
    height: auto;
    overflow: hidden;

    div {
      padding: 0.3rem 0.5rem;
      overflow: hidden;
    }

    span {
      position: static;
    }

    .mobile-only {
      display: initial;
      float: left;
      clear: left;
    }

    .mobile-hide {
      display: none !important;
    }
  }

  .new-nav-close-wrapper {
    width: 92% !important;
    height: 500px !important;
    left: 0 !important;
    display: none;
  }

  .new-nav-close-container {
    width: 0 !important;
    height: 0 !important;
    left: 0 !important;
  }

  /*S-Class Account*/
  .s-account-container h1 {
    margin-top: 0.5rem;
  }

  .s-account-actions button {
    font-size: 10px;
    padding: 7px 4px;
    margin-right: 4px;

    &:last-of-type {
      margin-right: 0px;
    }
  }

  .s-account-icon {
    background-size: 70px;
    width: 70px;
    height: 70px;
  }

  .s-account-content button {
    font-size: 10px;
  }

  .s-account-content .staff-table {
    margin-left: 0;

    p {
      font-size: 10px;
    }
  }

  .s-account-content .progress {
    width: 70%;
  }

  .s-account-logout-button {
    display: block;
  }

  .tutorial-icon {
    display: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {

  /*body
	{
		background: red;
	}*/

  .new-nav-controls form {
    width: 55%;
  }

  .new-nav-sale-items li a {
    font-size: 12px;
    padding: 5px 10px;
  }

  .new-nav-info-items li {
    font-size: 11px;
    margin-right: 20px;
  }

  .mobile-cart-button {
    span {
      margin: 6px 0px 10px 1px;
    }

    width: 34px;
  }

  .cart .brand-item .item-info {
    max-width: 77.5%;
  }

  /*Account*/
  .account-body .btn-group {
    display: none;
  }

  /*Recent Sales*/
  .recent-sale {
    display: none !important;
  }

  .become-a-reseller-button {
    display: none;
  }

  .new-nav-bottom {
    display: flex !important;
  }

  .home-brands:not(.home-brands-flex) .brand {
    padding-bottom: 49% !important;
    width: 49% !important;

  }

  .home-brands-flex .brand-block {
    width: 45% !important;

  }


  .home-brands a:nth-of-type(even) .brand {
    margin-right: 0 !important;
  }

}

.new-nav-close-wrapper {
  position: absolute;
  top: 0px;
  width: 30px;
  height: 20px;
  left: -20px;
  padding: 0px 20px 15px 10px;
  box-sizing: content-box;
  z-index: 10;
}

.new-nav-close-container {
  position: absolute;
  top: 0px;
  left: -10px;
  box-sizing: content-box;
  z-index: 11;
}

.joyride-hide {
  display: none;
}

.joyride-end {
  font-size: 8px;
  position: absolute;
  right: 5px;
  background: transparent;
  border: none;
  bottom: 4px;
  padding: 0;
}

.tutorial-icon {
  width: 30px;
  height: 25px;
  left: 3px;
  bottom: 32px;
  color: #3197EC;
  position: fixed;
  z-index: 110;
  cursor: pointer;

  i {
    font-size: 32px;
    text-align: center;
    width: 100%;
  }

  &.active {
    color: rgba(177, 177, 177, 0.36);
    cursor: auto;
  }
}

.tutorial-hover {
  display: none;
  position: fixed;
  left: 37px;
  bottom: 28px;
  background-color: #3197EC;
  color: white;
  border-radius: 2px;
  font-size: 11px;
  padding: 6px 9px;
  z-index: 110;
}

.tutorial-pointer-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  z-index: 105;
}

.tutorial-pointer {
  width: 500px;
  background: rgba(49, 151, 236, 0.85);
  padding: 20px;
  position: absolute;
  height: 220px;
  bottom: 38px;
  border-radius: 22px;
  color: white;
  left: 43px;

  h4 {
    font-weight: bold;
  }

  span {
    display: block;
    font-size: 13px;
  }

  i {
    font-size: 146px;
    transform: rotate(-40deg);
    position: absolute;
    left: -3px;
    bottom: -12px;
  }
}

.ajax-cat-cart-add {
  p {
    font-size: 14px;
    float: left;
    clear: left;

    &:nth-of-type(2) {
      margin-bottom: 5px;
    }

    &.lb-qty {
      margin-top: 15px;
      margin-right: 10px;
    }
  }

  .qty-selector {
    margin-top: 10px;
  }

  .custom-field-label {
    font-size: 14px;

    &.active {
      font-weight: bold;
    }
  }
}

.login-logo {
  max-width: 220px;
  margin: 0px 0px 20px 0px;
}

.login-header {
  display: none;
}

/*
** ICDS - INTERDYN COMPREHENSIVE DISCOUNTING SYSTEM
*/

.brand.ICDS {
  overflow: hidden;
  display: none;

  h4 {
    float: left;
    clear: left;
    display: none;

    &:first-of-type {
      margin-top: 0px;
    }
  }

  .ICDS-offer, .ICDS-p-offer {
    float: left;
    color: black;
    clear: left;
    border: solid 1px #6a8c6c;
    background: #c2e8c5;
    padding: 10px 10px 5px 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .ICDS-p-offer {
    background-color: #ffca00;
    border-color: #caa002;

    button {
      float: right;
      margin: 0;
      font-size: 13px;
      padding: 5px 10px;
      color: #000000;
      background: rgba(255, 255, 255, 0.62);
      border: rgba(255, 255, 255, 0.5) solid 1px;
    }
  }

  .offer-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0px;
    margin-bottom: 6px;
    display: inline;
    float: left;
  }

  .offer-value {
    font-size: 13px;
    display: block;
    float: right;

    strong {
      clear: both;
      display: block;
      text-align: right;
      font-size: 16px;
    }

    span {
      float: right;
    }
  }

  .offer-user-choice-container {
    font-size: 11px;
    float: left;
    clear: left;

    p {
      margin: 0;
      margin-right: 5px;
      float: left;
      display: inline;
    }

    form {
      float: left;

      input {
        margin: 0px 5px 0px 10px;
      }
    }
  }

  .offer-req {
    float: left;
    font-size: 11px;
    clear: left;
  }

  .offer-variants-open-heading, .offer-variants-closed-heading {
    float: left;
    font-size: 11px;
    cursor: pointer;
  }

  .offer-variants-open-heading {
    display: none;
  }

  .offer-variants-toggle-button {
    float: left;
    font-size: 11px;
    clear: left;
    cursor: pointer;
    margin-right: 5px;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;

    &.closed {
      transform: rotate(180deg);
    }
  }

  .offer-variants-container {
    float: left;
    clear: left;
    display: none;

    span {
      font-size: 11px;
    }

    .offer-variant {
      display: block;

      &:last-of-type {
        margin-bottom: 5px;
      }
    }

    .green-discount, .orange-required {
      font-weight: bold;
      color: green;

      .discount-per-item {
        font-weight: bold;
      }
    }

    .orange-required {
      color: #ea7b00;
    }
  }

  .freight-discounts-title {
    float: left;
    clear: left;
    font-size: 13px;
    margin-bottom: 2px;
  }

  .freight-discounts-legend {
    float: left;
    clear: left;
    font-size: 11px;

    i {
      color: black;
    }
  }

  .ICDS-freight-variants-container {
    float: left;
    clear: left;
    background: #c8e2f9;
    border: solid 1px #89c2f5;
    padding: 5px 10px;
    width: 100%;

    .freight-variant {
      font-size: 12px;
      display: block;
      margin-bottom: 3px;

      .green-discount {
        color: green;
        float: right;
        font-weight: bold;
      }

      i {
        color: black;
      }

      .freight-spacer {
        width: 15px;
        display: block;
        float: right;
      }
    }

  }

}

div.IDCS-discount-loader {
  background: url('../img/discount_loading.gif');
  width: 300px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 0px -40px;
  display: none;
}

/*
** Wizard Sections
*/

.wizard-section-header {
  font-size: 14px;
}

.wizard-section {
  overflow: hidden;
  padding: 0.975rem;
  border: solid #3197ec 2px;
  border-radius: 4px;
  background: rgba(49, 151, 236, 0.75);
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  .wizard-section-text {
    color: white;
    font-weight: lighter;
    font-size: 16px;

    span.count {
      float: right;
    }
  }

}

.wizard-sub-section, .wizard-sub-sub-section {
  float: left;
  clear: left;
  display: block;
  padding: 15px 25px;
  width: 100%;
  background: #a3d4fd;
  border-radius: 8px;
  margin-bottom: 15px;

  p.sub-section-text {
    color: #4c4c4c;
    font-size: 14px;

    span.count {
      float: right;
    }
  }
}

.wizard-sub-sub-section {
  background: #d2e8fb;
}

.wizard-section-sc-ss-sperator, .wizard-section-sc-s-sperator {
  font-size: 14px;
}

.wizard-section-sc-s-sperator {
  color: white;
}

.execute-qty-form {
  min-width: 0px !important;
  border: solid 1px #a0a0a0;
  padding: 2px 4px;
  background: #848383;
  color: white;
  display: none;
  cursor: pointer;
}

.cart-qty-loader {
  background: url('../img/mini-cart-loader.gif');
  width: 20px;
  height: 20px;
  background-size: 100%;
  display: none;
  margin-bottom: -6px;
}

/*Rep Bar*/
.rep-bar {
  width: 100%;
  background: #fad069;
  height: 35px;
  font-size: 13px;

  a {
    color: inherit;
  }

  .rep-bar-content {
    max-width: 91.875rem;
    margin: 0 auto;
    overflow: hidden;
    padding: 0px 0.9375rem;

    span {
      display: block;
      margin-top: 7px;
      float: left;
    }

    button {
      float: right;
      font-size: 13px;
      padding: 4px 6px;
      margin-top: 3px;
    }
  }
}

/* New Dealer Form */
#about-textarea-feedback {
  font-size: 12px;
  margin-top: -10px;

  span {
    margin-left: 12px;
    font-size: 10px;
  }
}

/*Dropship Popup*/
.freight-discount-popup, .custom-ref-popup {
  i {
    float: left;
    clear: left;
    margin-right: 10px;
    font-size: 27px;
  }

  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 1px;
  }

  p {
    font-size: 17px;
    float: left;
    clear: left;
    margin: 10px 0px 5px 0px;
  }

  small {
    float: left;
    font-size: 10px;
    clear: left;
    margin-bottom: 11px;
  }

  .btn {
    float: left;
    font-size: 12px;
  }

  .btn-secondary-outline {
    clear: none;
    margin: 10px 0px 10px 10px;
  }

}

/*FORM GROUP CHANGES  - This was designed for Price List*/
.form-group {
  .form-control-label {
    font-size: 15px;
    padding: 0;
    margin-bottom: 5px;
  }

  ul {
    list-style: none;
    padding: 0px;
  }

  li {
    float: left;
    clear: both;

    label {
      font-size: 12px;
      line-height: 13px;
    }

    input[type="checkbox"], input[type="radio"] {
      float: left;
      width: auto;
      margin-right: 5px;
      font-size: 12px;
      line-height: 12px;
    }
  }

  small {
    float: left;
    clear: left;
  }

}

/*
* Recent Sale CSS
*/

.recent-sale {
  height: 70px;
  width: 400px;
  background: white;
  margin: 0px;
  position: fixed;
  padding: 0px;
  bottom: 40px;
  left: 20px;
  /*box-shadow: 1px 1px 1px rgba(128, 128, 128, 0.41);*/
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.41);
  border: solid 1px #c7c7c7;
  display: none;
  z-index: 15;

  .rs-image-container {
    width: 25%;
    height: 100%;
    float: left;

    .image-aligner {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
      height: auto;
      width: 100%;
      max-height: 85%;
      max-width: 85%;
      margin-left: 7.5%;
      object-fit: contain;
    }
  }

  .rs-text-container {
    display: table;
    height: 70px;
    overflow: hidden;
    width: 72.5%;
    font-size: 12px;
    float: right;
    padding-right: 2.5%;

    div {
      display: table-cell;
      vertical-align: middle;

      p {
        margin: 0;
      }
    }
  }
}

.collection-description {
  font-size: 13px;
}

.completing-offer-value {
  display: inline-block;
  margin-top: 5px;
  color: #131313;
}

#id_brands /* Little hack for new dealer form */
{
  overflow: hidden;
}

/* Variant Selectors */

.selector-title {
  font-size: 10px;
}

.variant-selector {
  margin-top: 10px;
  clear: left;
  overflow: hidden;

  &:first-of-type {
    margin-top: 5px;
  }

  .selector-name {
    font-size: 11px;
    margin-bottom: 5px;
    display: inline-block;
  }
}

.variant-select-selector {
  color: white;
  background: #3197EC;
  border-radius: 0px;
  border: none;
  width: auto;
  word-wrap: break-word;
  line-height: 1.35;
  padding: 0rem 0.5rem;
  height: 34px;
  font-size: 12px;
  outline: none;
}

/* HOME BRANDS */
.home-brands {

  h4 {
    background-color: #ffffff;
    display: block;
    font-weight: bold;
    font-size: 14px;
    padding: 0.5rem 0.75rem;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #373a3c;
  }

  .brand {
    padding-bottom: 15%;
    background: #01315a;
    display: block;
    float: left;
    width: 15.00%;
    margin-right: 2%;
    margin-bottom: 2%;
    position: relative;

    h5 {
      position: absolute;
      bottom: 0px;
      font-size: 10px;
      padding-left: 5%;
      color: white;
    }

    .image-container {
      width: 80%;
      height: 80%;
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      top: 10%;
      left: 10%;
    }
  }

  a:nth-of-type(6n) .brand {
    margin-right: 0px;
  }

}

/* An updated version of home brands styling that uses no float: lefts and is much easier to work with. */
.home-brands-flex {
  gap: 20px;
  flex-wrap: wrap;

  .brand {
    aspect-ratio: 1/1;
    float: unset;
    padding-bottom: unset;
    margin-right: unset;
    width: unset;
  }

  .brand-block {
    width: 15%;
  }

}

/* Backorder Warning */

#backorder-warning-text {
  font-size: 14px;
  font-weight: bold;
  display: none;
}

#back-order-arrival-eta, #back-order-arrival-noeta {
  font-size: 14px;
  display: none;
}

#backorder-close {
  display: none;
}

/* Copied CSS for Catalogue Variant Selectors */

#fill-prod-variant-selector {
  margin-top: 10px;
  font-size: 12px;
  overflow: hidden;
  clear: left;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    button {
      margin: 0px 5px 5px 0px;
      background-color: #fff;
      color: #3197EC;
      border: 1px solid #3197EC;
      min-width: 70px;
      word-wrap: break-word;
      max-width: 90px;
      line-height: 1.35;
      padding: 0rem 0.5rem;
      min-height: 34px;
      float: left;
      outline: none;

      &.active {
        color: white;
        background: #3197EC;
      }

      &:disabled {
        /*border-color: #d4e8f9;
				background-color: #a9c8e2;
				color: #e8e7e7;*/
        opacity: 0.25;
        cursor: not-allowed;
      }
    }

    &.active {
      color: white;
      background: #3197EC;
    }
  }
}

/* POP UP CSS */

#pop-up-cover {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99998;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
}

#pop-up {
  width: 1200px;
  height: 675px;
  position: fixed;
  top: 10%;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 99999;

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
}

#video-pop-up-link {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
}

#pop-up-close {
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 8888;
  top: 0px;
  right: 0px;
  margin-right: -15px;
  margin-top: -15px;
  background: black;
  color: white;
  font-size: 23px;
  font-weight: normal;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}

@media (max-width: 1300px) {
  #pop-up {
    width: 80%;
    height: 580px;
  }

  #pop-up iframe {
    width: 100% !important;
    height: 100%;
  }
}

#add-to-cart-buttons, #added-to-cart-item-image, #added-to-cart-item {
  display: none;
}

#add-to-cart-buttons {
  margin-top: 40px;
  float: left;
  clear: left;
  width: 100%;

  a, span {
    border: solid 1px;
    padding: 5px;
    display: inline-block;
    float: left;
    cursor: pointer;
    color: #3197EC;
  }

  span {
    float: right;
  }
}

#added-to-cart-item-image {
  width: 82px;
  float: left;
}

#added-to-cart-item {
  margin-top: 40px;
  overflow: hidden;
}

#added-to-cart-item span {
  float: left;
  margin-left: 12px;
  font-size: 18px;
  width: calc(100% - 94px);
}

#added-to-cart-lb {
  background-color: white;
}

#added-to-cart-lb h6 {
  margin-bottom: 20px;
  text-align: center;
}

/* BLOG CSS */
@font-face {
  font-family: "Futura";
  src: url('../fonts/Futura-Book.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "Futura";
  src: url('../fonts/Futura-Medium.ttf');
  font-weight: 600;
}

@font-face {
  font-family: "Futura";
  src: url('../fonts/Futura-Bold.ttf');
  font-weight: 700;
}

#blog {
  font-family: 'Futura';

  #blog-header {
    background: white;
    padding: 20px;
    margin-bottom: 30px;

    a {
      color: black;
      text-decoration: none;
    }
  }

  .blog-filter-header {
    background: #dadada;
    padding: 10px 20px;
    margin-bottom: 20px;

    span {
      text-transform: capitalize;
    }
  }

  .blog-post {
    height: 375px;
    background: white;
    padding: 15px;
    margin-bottom: 30px;

    .blog-post-flex-container {
      display: flex;
      flex-flow: column;
      height: 320px;
      overflow: hidden;
    }

    .post-image {
      max-width: 100%;
      max-height: 200px;
      width: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
    }

    .post-category {
      color: #959494;
      font-size: 11px;
      display: block;
      margin-top: 7px;
    }

    .post-title {
      font-size: 18px;
      color: black;
      margin: 5px 0px;
      display: block;
    }

    .post-short-text {
      font-size: 13px;
      max-height: 75px;
      text-overflow: ellipsis;
      color: #606060;
      overflow: hidden;
    }

    .post-details {
      font-size: 12px;
      max-width: 70%;
      float: left;
      color: #9b9a9a;
      margin-top: 10px;
    }

    .post-reacts {
      max-width: 20%;
      float: right;
      font-size: 12px;
      color: #97c5ec;
      margin-top: 10px;
    }

    a {
      text-decoration: none;
    }
  }

  .page-link {
    border-radius: 0px;
    color: black;
  }

  .page-item.disabled .page-link, .page-item.disabled .page-link:focus, .page-item.disabled .page-link:hover {
    color: #606060;
  }

  .page-item.active .page-link, .page-item.active .page-link:focus, .page-item.active .page-link:hover {
    background: #97c5ec;
    border: #97c5ec;
  }

}

#blog-post {
  font-family: 'Futura';
  margin-top: 20px;
  padding: 20px 0px;

  .blog-post-background {
    background-color: white;
  }

  #bp-category, #bp-title, #bp-sub-title, #bp-date {
    text-align: center;
    display: block;
  }

  #bp-category {
    font-weight: 600;
    color: grey;
    text-transform: uppercase;
    font-size: 11px;

    a {
      color: inherit;
      display: inline-block;
      margin-right: 5px;

      &:after {
        content: '|';
        margin-left: 5px;
      }

      &:last-of-type:after {
        content: '';
        margin-left: 0px;
      }

      &:last-of-type {
        margin-right: 0px;
      }

    }

  }

  #bp-title {
    font-weight: 600;
    color: black;
    font-size: 26px;
    margin-top: 20px;
  }

  #bp-sub-title {
    font-size: 14px;
    color: #6b6b6b;
    margin-top: 15px;
  }

  #bp-date {
    font-size: 9px;
    color: black;
    margin-top: 10px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  #featured-image {
    display: block;
    max-width: 90%;
    margin: 0 auto;
  }

  .blog-post-content {
    h1 {
      font-weight: 600;
      color: black;
      font-size: 26px;
      margin: 20px 0px;
    }

    p, ul {
      font-size: 14px;
      color: #232323;
      font-weight: 400;
    }

    strong {
      font-weight: 700;
    }
  }

  .tags {
    margin-top: 15px;

    .blog-tag {
      display: block;
      text-align: center;
      text-transform: uppercase;
      padding: 3px 6px;
      border: solid 1px #5f5f5f;
      border-radius: 2px;
      float: left;
      margin-right: 10px;
      font-size: 12px;
      color: #5f5f5f;
    }
  }

  #bp-author {
    display: block;
    margin-top: 20px;
    color: #a0a0a0;
    font-size: 14px;

    a {
      color: inherit;
    }
  }

  .post-share-title {
    font-size: 11px;
    margin-bottom: 10px;
    display: block;
  }

  .post-recations {
    color: #c1c1c1;
    margin-left: 5%;
    margin-left: 33.3333%;
    margin-top: 15px;

    .reaction {
      display: block;
      background-color: #c1c1c1;
      color: white;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      cursor: pointer;

      &.reacted {
        background-color: black;
        cursor: auto;
      }

      &.rc {
        cursor: auto !important;
      }

      span {
        width: 30px;
        text-align: center;
        margin-top: 7px;
        display: inline-block;
      }
    }

    .reaction-count {
      font-size: 10px;
      margin-bottom: 10px;
      width: 30px;
      text-align: center;
      display: block;

      &.reacted {
        color: black;
      }
    }

  }

  .post-social {
    color: #c1c1c1;
    margin-left: 5%;
    margin-left: 33.3333%;
    margin-top: 35px;

    .post-facebook, .post-twitter {
      display: block;
      background-color: #c1c1c1;
      color: white;
      width: 30px;
      height: 30px;
      border-radius: 15px;

      span {
        width: 30px;
        text-align: center;
        margin-top: 7px;
        display: inline-block;
      }
    }

    .post-social-count {
      font-size: 10px;
      margin-bottom: 10px;
      width: 30px;
      text-align: center;
      display: block;
    }
  }

  .post-link {
    color: black;
    display: block;
    border: solid 1px black;
    padding: 10px;
    border-radius: 3px;
    font-size: 15px;
    margin: 0 auto;
    text-align: center;
    width: 250px;
  }
}

.related-blog-posts {
  margin-top: 40px;
  font-family: 'Futura';

  #related-blog-post-title {
    text-align: center;
    font-size: 20px;
    display: block;
    color: black;
    margin-bottom: 20px;
  }

  .related-blog-post {
    height: 280px;
    background: white;
    padding: 15px;
    display: flex;
    flex-direction: column;

    .post-image-container {
      flex: 2;
      overflow: hidden;
    }

    .post-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .post-title {
      font-size: 14px;
      color: black;
      margin: 5px 0px;
      display: block;
      overflow: hidden;
    }

    .post-details {
      font-size: 12px;
      max-width: 70%;
      float: left;
      color: #9b9a9a;
    }

    .post-reacts {
      max-width: 20%;
      float: right;
      font-size: 12px;
      color: #97c5ec;
    }

    a {
      text-decoration: none;
    }
  }
}

.news-ticker {
  width: 300px;
  position: absolute;
  background: white;
  right: 0;
  top: 90px;
  transition: width 1s ease;
  z-index: 11;
  border: solid 1px darkgrey;
  border-right: none;
  box-sizing: content-box;
  overflow: hidden;

  &.pre-initiation {
    width: 0px;
    border-left: none;
  }

  .nt-close {

    float: left;
    width: 50px;
    height: 50px;
    background: #3197ec;
    color: white;
    position: absolute;
    cursor: pointer;
    z-index: 10;

    i {
      font-size: 29px;
      display: block;
      text-align: center;
      margin-top: 10px;
      transition: all 1s ease;
    }

    &.rainbow {
      background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
      background-size: 1800% 1800%;
      -webkit-animation: rainbow 5s ease infinite;
      -z-animation: rainbow 5s ease infinite;
      -o-animation: rainbow 5s ease infinite;
    }
  }

  &.closed {
    width: 50px;

    .nt-row {
      height: 50px !important;
    }

    .nt-small-header {
      opacity: 1 !important;
    }

    .nt-close i {
      transform: rotate(180deg);
    }

    .nt-header span {
      opacity: 0;
    }
  }

  .nt-header {
    height: 50px;
    width: 100%;
    background: #ececec;

    span {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      display: block;
      color: black;
      padding-top: 13px;
      transition: opacity 1s ease;
      width: 300px;
      padding-left: 50px;
      box-sizing: border-box;
    }
  }

  .nt-row-container {
    max-height: 700px;
    overflow-y: auto;
  }

  .nt-row {
    width: 100%;
    transition: height 1s ease;
    overflow: hidden;
    position: relative;

    .nt-small-header {
      width: 50px;
      float: left;
      opacity: 0;
      transition: opacity 1s ease;
      height: 50px;
      line-height: 45px;
      text-align: center;
      position: absolute;
      background-color: #3b3b3b;

      span {
        font-size: 10px;
        width: 50px;
        overflow: hidden;
        padding: 5px 0px;
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        color: white;
      }
    }

    &:nth-of-type(even) {
      .nt-small-header {
        background-color: #282828;
      }
    }

    .nt-content {
      float: left;
      margin-top: 6px;
      padding: 0px 10px 6px 10px;
      width: 280px;
      border-bottom: solid 1px #eaeaea;

      .nt-title {
        font-size: 14px;
        font-weight: bold;
        display: block;
        text-align: center;
        color: black;
      }

      .nt-image {
        max-width: 80%;
        display: block;
        margin: 7px auto 0px auto;
      }

      .nt-text {
        font-size: 12px;
        text-align: center;
        margin: 7px 10px;
      }

      .nt-link {
        color: #3197ec;
        font-size: 12px;
        display: block;
        text-align: center;
        margin-bottom: 5px;
      }

      .nt-reacts-wrapper {
        text-align: center;
        width: 100%;
        overflow: hidden;

        .nt-reacts {
          overflow: hidden;
          margin: 0 auto;
          display: inline-block;

          .react {
            float: left;
            margin-right: 7.5px;
            margin-left: 7.5px;

            span {
              &:first-of-type {
                width: 20px;
                height: 20px;
                background-color: #c1c1c1;
                text-align: center;
                font-size: 12px;
                padding-top: 5px;
                box-sizing: border-box;
                border-radius: 10px;
                color: white;
                float: left;
                cursor: pointer;
              }

              &:last-of-type {
                margin-left: 4px;
                display: block;
                float: left;
                font-size: 14px;
                color: #c1c1c1;
                margin-top: -1px;
              }
            }

            &.reacted {
              span:first-of-type {
                background-color: black;
                cursor: auto;
              }

              span:last-of-type {
                color: #545454;
                cursor: auto;
              }
            }
          }

        }

      }
    }

    &:last-of-type {
      .nt-content {
        border-bottom: none;
      }
    }
  }
}

@media (max-width: 560px) {

  .news-ticker {
    display: none;
  }

  .nt-row-container {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 12px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .5);
      border-radius: 10px;
      border: 2px solid #ffffff;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }
  }

}

#blog-author {
  padding: 15px 0px;
  display: flex;
  font-family: 'Futura';
  color: #000000;

  .author-img-container {
    min-width: 200px;
  }

  .author-details {
    margin-left: 20px;

    h3 {
      font-weight: bold;
      margin: 0px 0px 10px 0px;
      padding: 0px;
      font-weight: 600;
      font-size: 18px;
      color: #3197ec;
    }

    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.loading-animation {
  .spinner {
    width: 200px;
    height: 40px;
    position: relative;
    margin: 10px auto;
  }

  .double-bounce1, .double-bounce2, .double-bounce3, .double-bounce4 {
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background-color: #0099CC;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2, .double-bounce4 {
    -webkit-animation-delay: -0.25s;
    animation-delay: -0.25s;
    left: 50px;
  }

  .double-bounce3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
    left: 100px;
  }

  .double-bounce4 {
    -webkit-animation-delay: -0.75s;
    animation-delay: -0.75s;
    left: 150px;
  }

  @-webkit-keyframes sk-bounce {
    0%, 100% {
      -webkit-transform: scale(0.1)
    }
    50% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.1);
      -webkit-transform: scale(0.1);
    }
    50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
}

[v-cloak], .cloak {
  display: none;
}

.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 5px 10px 4px;
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;
    }

    &[x-placement^="top"] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="bottom"] {
      margin-top: 5px;

      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^="right"] {
      margin-left: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^="left"] {
      margin-right: 5px;

      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }
  }

.callout {
            padding: 20px;
            margin: 20px 0;
            border: 1px solid #f0ad4e;
            border-left-width: 5px;
            border-radius: 3px;
            font-size: 12px;
        }

        .callout h4 {
            margin-top: 0;
            margin-bottom: 5px;
            color: #f0ad4e;
            font-size: 18px;
        }

        .callout p:last-child {
            margin-bottom: 0;
        }

#address-selector-tabs {
  .nav-item {
    &:nth-child(1) {
      margin-left: 10px;
    }
  }
  .nav-link{
    &:not(.active) {
      background-color: #EAEAEA;
      &:hover {
        background-color: #DADADA;
      }
    }
  }
}

.product-freight-estimate, .product-admin-links {
  //
  //& > div {
    background-color: white;
    border: $site-lightgrey solid 0.1px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
  //}
}

.product-admin-links {
  .list-group-item-action {
    font-size: 11px;
    line-height: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

//.product-freight-estimate

  /*h6 {
    font-size: 16px;
    font-weight: $site-lightfont;
    margin: 0;
  }

  .row {
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;

    & > div {
      display: table-cell;
      vertical-align: top;
      float: none;
    }
  }

  span {
    float: left;
    clear: left;
    display: block;
  }

  .title {
    font-size: 14px;
  }

  .brand {
    font-size: 11px;
  }

  div.cost {
    margin-top: 7px;
    float: left;
    clear: left;
  }

  span.cost {
    font-size: 18px !important;
  }

  .btn {
    margin-top: 10px;
    float: left;
    clear: left;
  }

  .image-container {
    width: 90%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  display: block;

  .title {
    font-size: 12px;
    font-weight: lighter;
    color: $site-blue;

    span {
      float: none;
      display: inline;
    }
  }

  span.cost {
    font-size: 15px !important;
  }

  .btn {
    float: right;
    clear: none;
    margin-top: 8px;
  }

  .row {
    & > div {
      display: block;
    }
  }*/

.home-edit-links {
  .btn {
      padding: 0 10px 0 10px;
      @media (max-width: 600px) {
        display: block;
        float: none;
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
    }
    }

  @media (min-width: 1050px) {
    position: fixed;
    left: 0;
    top: 300px;
    writing-mode: vertical-rl;
    text-orientation: sideways;
    .btn {
      padding: 10px 0 10px 4px;
    }
  }

  //@media (max-width:1050px) {
  //    position: unset !important;
  //}
}
#products-container-joyride, .home .best-sellers {
    display: grid;
    grid-template-columns: repeat(auto-fill, max(170px, calc(100% / 4 - 20px)));
    column-gap: 10px;
    justify-content: center;
}

.home {
  .best-sellers-title {
  //display: none;
  text-align: center;
  margin-bottom: 0.5rem;
}
  .best-sellers {
    //display: none;
    padding: 10px;
  }
  .best-sellers-description {
    font-size: 14px;
  }
}


.brand-incentive
{
  background: #ffee0a !important;
}


.returns-form {
  .uploaded-file {
    width: 100%;
    height: 80px;
    padding: 10px;
    background-color: #FCFCFC;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    display: block;
    line-height: 60px;
    color: black;
    margin-bottom: 30px;
    .fas {
      margin-right: 10px;
    }
  }
}


.label-dark {
  @include label-variant($site-purple);
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

@keyframes primary-blinking {
  00% {
    background-color: $btn-primary-bg;
  }

  50% {
    background-color: $site-purple;
  }

  100% {
    background-color: $btn-primary-bg;
  }
}

.btn-primary-blinking {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  animation: primary-blinking 1300ms infinite;
}

.step-container {
  width: 100%;
  margin-bottom: 100px;

  .step-progress-bar {
    list-style: none;

    li {
      float: left;
      width: 20%;
      position: relative;
      text-align: center;


      &::before {
        content: '\f111';
        width: 30px;
        height: 30px;
        border: 2px solid #bebebe;
        display: block;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        line-height: 27px;
        background: white;
        color: #bebebe;
        text-align: center;
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: #979797;
        top: 15px;
        left: -50%;
        z-index: -1;
      }

      &:first-child::after {
        content: none;
      }

      &.done {
        &::after {
          background: #3aac5d;
        }

        &::before {
          border-color: #3aac5d;
          background: #3aac5d;
          color: white;
          content: '\f00c';
        }
      }

      &.active {
        &::after {
          background: #3aac5d;
        }

        &::before {
          border-color: #31b0d5;
          background: #31b0d5;
          color: white;
          content: '\f141';
        }
      }
    }
  }

}

.reboxed-list {
  .section {
    margin-bottom: 30px;
  }
  .product {
    .btn-primary {
      background-color: #091a4f;
    }
    .product-thumbnail {
      width: 100px;
      height: 80px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    h4 {
      margin-left: 20px;
      margin-top: 30px;
    }
    ul {
      li {
        //float: left;
        //margin-right: 20px;
        font-size: 12px;
      }
    }
    .price-container {
      width: 160px;
      height: 80px;
      text-align: center;
      font-size: 14px;
      .price-value {
        font-size: 30px;
      }
    }
    .grade-container {
      width: 80px;
      height: 80px;
      margin-top: auto;
      margin-bottom: auto;
      padding: 3px;
      text-align: center;
      border-radius: 10px;
      text-transform: uppercase;
      color: white;
      font-weight: bolder;
      .grade-value {
        font-size: 40px;
        font-weight: normal;
        height: 40px;
        line-height: 40px;
        margin-top: 0;
        margin-bottom: auto;
      }
    }
  }
}

.reboxed-detail {

  .address {
    border: $site-lightgrey solid 1px;
    float: left;
    min-height: 150px;
    min-width: 300px;
    margin: 10px;
    padding: 10px;
    position: relative;
    cursor: pointer;
    i {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 3em;
      display: none;
      color: $site-green;
    }
    &.selected {
      background-color: $site-verylightgrey;
      i {
        display: block;
      }
    }
  }

  .callout {
        text-align: left;
        border: 1px solid #3197EC;
        border-right-width: 5px;
        margin-top: 23px;
        min-height: 440px;
        font-size: 18px;

        h4 {
            margin-top: 0;
            margin-bottom: 20px;
            color: #3197EC;
            font-size: 24px;
            font-weight: bold;
            text-align: left;
        }

        .strong {
            font-weight: bold;
        }
    }
  @media (max-width: 799px) {
    .tools-menu {
      display: none;
    }
    .edit-menu {
      clear: both;
      margin-bottom: 30px;
      .btn {
        margin-top: 20px;
        padding: 0.75rem 1.25rem;
      }
      .dropdown-item {
        padding-top: 20px;
        padding-bottom: 20px;
        &:nth-child(n + 2) {
          border-top: #0C0B0B solid 2px;
        }
      }
    }
  }
  @media (min-width: 800px) {
    .tools {

      .dropdown {
        float: right;
        .dropdown-menu {
          right: 0;
          left: auto;
        }
      }

    }
  }
  background-color: white;
  padding: 0.975rem;
  border: $site-lightgrey solid 0.1px;
  height: auto;
  .title {
    font-size: 22px;
  }
  .subtitle {
    font-size: 16px;
  }
  img {
    margin: 15px;
  }
  margin-bottom: 30px;
  .item-info-box {
      background: aliceblue;
      box-sizing: border-box;
      width: 100%;
      //display: block;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      h3 {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 2px;
        margin-top: 0;
      }
      .dealer-price-loading {
        text-align: center;
        font-weight: bolder;
        font-size: 10px;
      }
      .dealer-price-loaded {
        display: none;
      }
      .white {
        background-color: white;
        border-radius: 20px;
        padding-left: 10px;
        padding-right: 10px;
      }
      .attribute {
        margin-bottom: 4px;
        .label {
          font-size: 24px;
        }
        small {
          font-size: 10px;
        }
        &.rrp {
          font-size: 36px;

        }
      }
      .go-price, .dealer-price {
        font-size: 28px;
      }
      .dealer-price-visible {
        display: none;
      }
    }
}

a.silent {
  color: inherit;
}

.text-center {
  text-align: center;
}

.black-fortnight-2022-nav-link {
  background: #FF5757;
  color: white !important;
  font-weight: 900 !important;
  font-size: 16px;
  &:hover {
    color: white !important;
    background: #21C2B2;
  }
}

.december-sale-2022-nav-link {
  background: rgb(252,163,84);
  background: linear-gradient(90deg, rgb(252 113 84) 0%, rgba(242,37,74,1) 100%);
  color: white !important;
  text-transform: uppercase;
  &:hover {
    background: #0047FF;
    background: linear-gradient(120deg, #7396fa 0%, #0047FF 100%);
  }
}

.cost.discount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    color: red;
    margin-top: 6px;
    font-weight: bold;
}

.clearance-corner-badge {
    display: block;
    float: left;
    font-size: 13px;
    background: #ff2b58;
    padding: 4px;
    border-radius: 3px;
    font-weight: bold;
    margin-left: 7px;
    color: white;
    width: calc(100% - 14px);
    text-align: center;
}

.product-block div.discount, .discount-bubble {
    text-align: center;
    background-color: orange;
    font-weight: 700;
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    border-radius: 50%;
    font-size: 9px;
    padding-top: 2px;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 0.9;
}

.product-block div.discount .amount, .discount-bubble .amount {
    font-size: 20px;
}


.discount-bubble, .product-block div.discount {
    background: #ff2b58;
    color: white;
    line-height: 1;
    box-shadow: 1px 1px 3px #ba8293b0; 
    top: 5px;
    right: 5px;
}

.discount-bubble .amount, .product-block div.discount .amount {
    font-size: 22px;
}


/* Hide product page discount bubbles when the gallery is expanded */
.new-product-gallery-wrapper.expanded .discount-bubble {
  display: none;
}

/* Simple helper class to centre a row. */
.centered-row {
  display: flex;
  justify-content: center;
}

/* Clearance Corner styling */
.clearance-corner-logo-container {
  max-width: 465px;
  width: 100%;
  margin: 0 auto;
}

.clearance-corner-logo {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.home .clearance-corner-widget {
  background: url('../img/cc-background.jpg');
  background-size: 22%;
}

.clearance-corner-widget .product-block div.discount .amount
{
  font-size: 27px;
}

@media only screen and (max-width: 800px) {
.clearance-corner-widget .product-block div.discount .amount {
    font-size: 18px;
}
}

.clearance-corner-widget-button {
  border: none;
  background: #ff2a58;
  color: white;
  box-shadow: 1px 1px 3px 0px #b17e9e94;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(90deg, rgb(255, 2, 8) 0%, rgb(217, 0, 3) 100%)
}

.cc-logo-widget {
  max-width: 300px;
  width: 85%;
}

.clearance-corner-widget-button:hover {
  background: rgb(255, 2, 8) 0%;
  color: white;
}

.clearance-corner-brands .brand {
  background: #ff2b58;
}
.clearance-corner-header h1,
.clearance-corner-title {
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.clearance-corner-description {
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
  color: #ff2c59;
  width: 100%;
  padding: 17px;
  text-align: center;
  font-size: 18px;
}

.clearance-corner-explainer {
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
  color: #ff2c59;
  padding: 0.5em;
  font-size: 32px;
}

.clearance-corner-header-text {
  color: #ff2c59;
  margin-top: 20px;
  padding: 0;
  font-size: 32px;
}

#site-container:has(.clearance-corner-header) {
  background: url('../img/cc-background.jpg');
  background-size: 22%;
}

// Bargain Basement Styling
.bargain-basement-header {

}

.bargain-basement-logo-container {
  max-width: 250px;
  width: 100%;
  margin: 0 auto;
}

.bargain-basement-logo {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  margin-bottom: 20px;
}

// #site-container:has(.bargain-basement-header) {
//   // background: url('../img/bargain-basement-background.jpg');
//   // background-size: 22%;
//   background: url('http://localhost:8080/static-local/theme/ID15/images/bargain-basement-background.jpg');
//   background-size: cover;
//   background-position: bottom;
//   background-attachment: fixed;
// }

#site-container:has(.bargain-basement-header) {
  background: linear-gradient(145deg, rgb(255 255 255) 0%, rgb(215 215 215) 100%);
  background-attachment: fixed;
}

#site-container:has(.bargain-basement-header) .breadcrumb {
  visibility: hidden;
}

.bargain-basement-text {
  color: #333232;
  font-weight: 400;
  text-align: center;
  margin-top: 25px;
}

.bargain-basement-text a {
  color: inherit;
  text-decoration: underline;
}

.bargain-basement-products-background {
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  width: 95%;
  padding: 40px 0;
}

.bargain-basement-side-nav {
  padding-left: 15px;
}

.bargain-basement-side-nav li {
  list-style: none;
  padding: 0;
}

.bargain-basement-side-nav li a {
  color: inherit;
}

.bargain-basement-side-nav li a.selected {
  color: #fd0206;
}

.bargain-basement-categories-header {
  color: #696969;
  margin-top: 30px;
  display: block;
  padding-left: 15px;
  margin-bottom: 5px;
}

.bargain-basement-categories-header::after {
  content: ":";
}

.bargian-basement-no-products-warning {
  display: block;
  width: 100%;
}

.product-block div.discount.bargain-basement, .discount-bubble.bargain-basement {
  font-size: 18px;
  top: 10px;
  right: 10px;
  border-radius: 0;
  width: auto;
  aspect-ratio: auto;
  background: linear-gradient(90deg,rgb(255 2 8) 0%, rgb(217 0 3) 100%);
  padding: 5px 15px;
  color: #ffffff;
  box-shadow: 1px 1px 2px #8380808c;
  z-index: 3;
  font-weight: bold;
}

.product-block div.discount.bargain-basement small, .discount-bubble.bargain-basement small {
  font-size: 8px;
  text-align: left;
  display: block;
  width: 100%;
  margin-bottom: 1px;
}

.discount-bubble.bargain-basement {
  font-size: 30px;
  right: 0;
  top: 0;
}

.clearance-corner-badge.bargain-basement {
  background: linear-gradient(90deg,rgb(255 2 8) 0%, rgb(217 0 3) 100%);
  border-radius: 0;
}



#bbBrandSelect {

  /* styling */
  background-color: white;
  border: none;
  border-radius: 0px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  font-weight: bold;

  /* reset */
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, #ffffff 50%), linear-gradient(135deg, #ffffff 50%, transparent 50%), linear-gradient(to right, #fd0206, #db0003);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

#bbBrandSelect:focus {
  background-image:
    linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, gray, gray);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0
}


.btn-group-block {
  display: flex;
  width: 100%;
  border-radius: 0;
  .btn {
    flex: 1;
    border-radius: 0;
  }
}

  .clearance-corner-brands .brand {
      box-shadow: 2px 1px 3px 0px #c6b1c4;
  }



#local-store-product-detail--reboxed-items-banner {
  display: none;
  margin-left: auto;
  margin-right: auto;
  width: 41em;
  background-color: black;
  height: 4em;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  z-index: 1;
  h1.title {
    transform: skew(-10deg) rotate(-1deg);
    position: absolute;
    font-size: 1.3em;
    background-color: red;
    display: inline-block;
    left: 2%;
    top: 25%;
    padding-left: 0.4em;
    padding-right: 0.4em;
    padding-top: 0.2em;
    padding-bottom: 0.1em;
    font-weight: bold;
  }
  h2 {
    transform: rotate(-1deg);
    position: absolute;
    font-size: 1.1em;
    display: inline-block;
    font-weight: bold;
    text-align: center;
    span {
      font-size: 1.5em;
    }
    &.max-discount {
      left: 65%;
      bottom: 0;
    }
    &.items-count {
      right: 40%;
      bottom: 10%;
      span {
      font-size: 2em;
    }
    }
  }

  &:hover {
    .shop-now {
      text-decoration: none;
      background-color: white;
      color: $site-red;
      &::before, &::after {
        background-color: white;
      }
    }
  }
  .shop-now {
    transform: rotate(-1deg);
    position: absolute;
    right: 5%;
    top: 0.3em;
    color: white;
    background-color: red;
    font-size: 1.5em;
    width: 70px;
    text-align: center;
    line-height: 1.0em;
    height: 2em;
    display: block;
    text-decoration: none;
    &::before {
      content: "";
      width: 30%;
      height: 100%;
      transform: skew(-15deg);
      background-color: red;
      position: absolute;
      left: -15%;
      z-index: -1;
      top: 0;
    }
    &::after {
      content: "";
      width: 40%;
      height: 100%;
      transform: skew(-10deg);
      background-color: red;
      position: absolute;
      right: -10%;
      z-index: -1;
      top: 0;
    }
  }
}
/* https://interdyn.slack.com/archives/G50HEC12R/p1705623848585919?thread_ts=1705615931.677519&cid=G50HEC12R */
.new-product-info-box:has(.clearance-corner-badge) .go_price_title {background: linear-gradient(90deg, rgb(255, 2, 8) 0%, rgb(217, 0, 3) 100%);}.new-product-info-box:has(.clearance-corner-badge) div.go-price {border-color: #d90002}


.brand.cart-upsell {
  background: #e93e40;color: white;border-color: black; cursor: pointer;
}

.cart-upsell-add-button i, .cart-upsell-add-button.loading span {
  display: none;
}

.cart-upsell-add-button.loading i {
  display: inline-block;
}

.cart-upsell .brand-item {
    margin-top: 7px;
    color: black;
}

.cart-upsell .item-total {
    margin-right: 10px;
}

.item-total.item-rrp span {
    color: grey;
}

.item-total.item-rrp span:last-of-type {
    text-decoration: line-through;
}

.cart-upsell-add-button, .cart-upsell-add-button span {
  cursor: pointer;
}

.cart-upsell-button-container {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 30px;
    padding: 7px 10px 10px;
    background: #e93e40;
    color: white;
    cursor: pointer;
}

.sonic-boom-progress {
  max-width: 350px;
  height: auto;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  z-index: 100;
  padding: 10px 15px 10px 10px;
  border: 1.5px solid #CDCDCD;
  display: none;
}

.sonic-boom-button {
  color: white;
  background-color: #ff00ff;
}

.sonic-boom-button.complete span {
  color: #2ef52e;
}

.sonic-boom-progress-inner {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
}

.sonic-boom-progress span.complete {
  color: $site-green;
}

// @font-face {
//   font-family: SonicBoom;
//   src: url(https://res.cloudinary.com/interdyn/raw/upload/v1727300082/NB_Architekt_Pro_Neue_Regular_hjhnf3.otf);
// }

// .id15-sonic-boom h1, .id15-sonic-boom h4, .id15-sonic-boom p {
//   font-family: SonicBoom;
// }

// h1 {
// font-weight: bold;
// font-size: 40px;
// text-align: center;
// }

// .sonic-boom p {
// font-weight: bold;
// font-size: 25px;
// }

.id15-sonic-boom .video-container {
	position: relative;
	width: 100%;
	padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.id15-sonic-boom .video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.text-sonic-boom-pink {
  counter-reset: #ff00ff;
}

.cart .brand-item div.sonic-boom-cart-label {
  width: auto;
  background: none;
  transform: scale(0.75);
  transform-origin: left;
  margin-left: 5px;
}

.cart .brand-item div.sonic-boom-cart-label span {
  margin: 0 !important;
}

@media only screen and (max-width: 650px) {
  .new-product-container .new-product-heading .product-top-toolbar {
    position: relative;
    margin-top: 4px;
    float: left;
    clear: left;
  }
}