@font-face {
    font-family: 'Familiar Pro';
    src: url('../fonts/Familiar-Pro-Regular.otf');
}

@font-face {
    font-family: 'Familiar Pro';
    src: url('../fonts/Familiar-Pro-Bold.otf');
    font-weight: bold;
}


@font-face {
    font-family: 'Familiar Pro';
    src: url('../fonts/Familiar-Pro-Light.otf');
    font-weight: lighter;
}



// Add variables here

$site-background: #F2F2F2;
$site-blue: #3197EC;
$site-purple: #555599;
$site-darkgrey: #3D3D3D;
$site-lightgrey: #D8D8D8;
$site-verylightgrey: #F4F4F4;
$site-white: #FFFFFF;
$site-greyindenttext: #3E3E3E;
$site-black: #000000;
$site-middlegrey: #CACACA;
$site-middlegreydark: #A7A7A7;
$site-green: #73B372;
$site-red: #d9534f;

$site-fontfamily: 'Helvetica', 'Arial';
$site-lightfont: lighter;
$site-regularfont: normal;
$site-boldfont: bold;


$site-standard-width: 1440px;



/*ICONS*/
.wave
{
	background-image: url('../img/icons/wave.png');
}
.usb
{
	background-image: url('../img/icons/USB.png');
}
.turntable
{
	background-image: url('../img/icons/turntable.png');
}
.bluetooth
{
	background-image: url('../img/icons/bluetooth.png');
}
.chip
{
	background-image: url('../img/icons/chip.png');
}

/*Brand Logos*/
#devialet, #Devialet{background-image: url('../img/brands/devialet.png');}
#oppo, #Oppo{background-image: url('../img/brands/oppo.png');}
#ortofon, #Ortofon{background-image: url('../img/brands/ortofon.png');}
#polk, #Polk{background-image: url('../img/brands/polk.png');}
#pro-ject, #Pro-Ject{background-image: url('../img/brands/pro-ject.png');}
#rotel, #Rotel{background-image: url('../img/brands/rotel.png');}
#sim2, #Sim2{background-image: url('../img/brands/sim2.png');}
#tivoli, #Tivoli{background-image: url('../img/brands/tivoli.png');}
